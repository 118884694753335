import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-continue-button',
    templateUrl: 'button.component.html',
})
export class ContinueButtonComponent extends ButtonComponent {

    @Input() override label = 'inputs.continue';
    @Input() override icon: string | null = 'pi-arrow-right';
    @Input() override styleClass = 'p-button-primary';
    // Deprecated - use clickEvent
    @Input() override onClick: CallableFunction = function () {};
    @Output() override clickEvent = new EventEmitter();

}
