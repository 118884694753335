<div class="flex">
    <ng-container [ngSwitch]="filterable.type">
        <ng-container *ngSwitchCase="'date'">
            <!-- Date Range -->
            <p-calendar *ngIf="selectedOp === 'BETWEEN' || selectedOp === 'NBETWEEN'; else dateTpl" [(ngModel)]="date2"
                [showButtonBar]="true" [firstDayOfWeek]="1" (ngModelChange)="updateDate(2, $event);" appendTo="body"
                dateFormat="dd.mm.yy"></p-calendar>
            <!-- Date -->
            <ng-template #dateTpl>
                <p-calendar [(ngModel)]="date" [showButtonBar]="true" [firstDayOfWeek]="1"
                    (ngModelChange)="updateDate(1, $event);" appendTo="body" dateFormat="dd.mm.yy"></p-calendar>
            </ng-template>
        </ng-container>

        <!-- Select -->
        <ng-container *ngSwitchCase="'select'">
            <p-dropdown appendTo="body" [options]="filterable.selectOptions() || []" [(ngModel)]="value" (onChange)="valueChanged()" optionValue="value"
                optionLabel="name" [showClear]="true" styleClass="w-full">
                <ng-template pTemplate="item" let-item>
                    {{ item.name | translate }}
                </ng-template>

                <ng-template pTemplate="selectedItem" let-item>
                    {{ item.name | translate }}
                </ng-template>
            </p-dropdown>
        </ng-container>

        <!-- default -->
        <ng-container *ngSwitchDefault>
            <input id="text1" type="text" pInputText class="w-full" [(ngModel)]="value" (ngModelChange)="valueChanged()" />
            <input *ngIf="selectedOp === 'BETWEEN' || selectedOp === 'NBETWEEN'" id="text2" type="text" pInputText
                class="w-full" [(ngModel)]="value2" (ngModelChange)="valueChanged()" />
        </ng-container>
    </ng-container>

    <!-- show value filter types -->
    <ng-container *ngIf="!filterable.hideOperators">
        <p-overlayPanel #op>
            <ng-template pTemplate="content">
                <p-listbox [options]="filterOps" [(ngModel)]="selectedOp" (ngModelChange)="valueChanged()"
                    (onClick)="op.toggle($event)">
                    <ng-template let-item pTemplate="item">
                        {{ 'inputs.filters.' + item | translate }}
                    </ng-template>
                </p-listbox>
            </ng-template>
        </p-overlayPanel>
        <p-button (click)="op.toggle($event)" icon="pi pi-filter" severity="secondary" [text]="true"
            [rounded]="true"></p-button>
    </ng-container>
</div>
