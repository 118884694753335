import { Injectable } from '@angular/core';
import localeCz from '@angular/common/locales/cs';
import { registerLocaleData } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { LanguageEnum } from '../modules/shared/models/language.enum';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  locale: 'en-US' | 'cs-CZ' = 'en-US';
  groupId$ = new BehaviorSubject<string>('1234');

  registerLanguage(language: LanguageEnum | string): void {
    if (language === LanguageEnum.ENGLISH) {
      this.locale = 'en-US';
    }
    if (language === LanguageEnum.CZECH) {
      this.locale = 'cs-CZ';
      registerLocaleData(localeCz);
    }
  }
}
