import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-switch-depre',
  templateUrl: './input-switch-depre.component.html',
  styleUrls: ['./input-switch-depre.component.scss'],
})
export class InputSwitchDepreComponent {
  @Input() textStyleClass = '';
  @Input() label = '';
  @Input() value!: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  emitChangeEvent(): void {
    this.valueChange.emit(this.value);
  }

  switch(): void {
    this.value = !this.value;
    this.emitChangeEvent();
  }
}
