<app-input-search
    [form]="form"
    [autocomplete]="false"
    (focusin)="op.show($event)"
    placeholder="{{ label | translate }}"
    class="fulltext-search"
>
    <p-overlayPanel #op>
        <p-listbox
            [options]="searchOptions"
            [(ngModel)]="selectedOptionSearch"
            (onChange)="listSearchChange($event)"
            emptyMessage="{{'database.persons.noResultsFound' | translate}}"
            optionLabel="createdAt"
            styleClass="h-full"
        >
        </p-listbox>
        <div class="searchedText">
            <div [innerHTML]="selectedOptionSearchData | safeHtml"></div>
            <p-divider *ngIf="searchOptions.length"></p-divider>
            <div class="dialogButtons">
                <p-button
                    *ngIf="selectedOptionSearchData"
                    (onClick)="accept();op.hide();"
                    label="{{ 'fulltext.accept' | translate }}"
                    class="white-space-nowrap"
                ></p-button>
            </div>
        </div>
    </p-overlayPanel>
</app-input-search>
