import { Component, Input, ViewChild } from '@angular/core';
import { AddressDetailObject } from '@it2go/types';
import { Map } from 'ol';
import { MapComponent } from '../map/map.component';
import { MapService } from '../map/map.service';

// TODO zjistit kde vsude se da pouzit tahle komponenta, misto toho aby se to vse psalo znova
@Component({
    selector: 'app-map-link',
    templateUrl: './map-link.component.html',
})
export class MapLinkComponent {
    @Input() data: AddressDetailObject | null = null;
    @Input() linkText: string = '';
    @Input() tooltip: string = '';

    displayMap = false;
    mapObject: Map = this.mapService.createMapObject();

    @ViewChild('map') map!: MapComponent;

    constructor(private readonly mapService: MapService) {}

    async showOnMap(address: AddressDetailObject) {
        this.displayMap = !this.displayMap;

        this.map.open();
        await this.mapService.showAddressOnMap(this.mapObject, address);
    }
}
