import { Component, Input } from '@angular/core';
import { FloatingMessageComponent } from './floating-message.component';

@Component({
    selector: 'app-floating-warn-message',
    templateUrl: 'floating-message.component.html',
    styleUrls: ['./floating-message.component.scss'],
})
export class FloatingWarnMessageComponent extends FloatingMessageComponent {

    @Input() override severity = 'warn';
    @Input() override message: string | null = '';

}
