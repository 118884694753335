import { Injectable } from '@angular/core';
import { Feature, Map, MapBrowserEvent, Overlay, View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Vector } from 'ol/source';
import { Icon, Stroke, Style } from 'ol/style';
import { Polyline } from 'ol/format';
import { environment } from 'packages/client/src/environments/environment';

@Injectable()
export class MapService {
    constructor() {}

    /**
     * Creates map object with default view on center of CZ.
     * @param coords coordinates to center on (defaults on center of CZ)
     * @param zoom zoom level 1-16 (defaults on 8)
     * @returns Map object
     */
    public createMapObject(
        coords: [number, number] = [15.4749126, 49.8037633],
        zoom = 8,
    ): Map {
        return new Map({
            view: new View({
                center: fromLonLat(coords), // Center of Czech Republic
                zoom: zoom, // +- whole Czech Republic
            }),
        });
    }

    /**
     * Queries mapy.cz API for suggested addresses from user input.
     * @param query user search input
     * @param num number of returned suggestions
     * @returns array of suggested addresses
     */
    public async suggestAddresses(query: string, num = 5): Promise<Address[]> {
        const url = new URL(environment.mapSuggestUrl);
        url.searchParams.append('query', query);
        url.searchParams.append('limit', `${num}`);
        url.searchParams.append('lang', 'cs');
        ['regional.address'].forEach((type) =>
            url.searchParams.append('type', type),
        );

        const response = await fetch(url.toString(), {
            mode: 'cors',
        });
        const json = await response.json();

        // console.log('suggest', json);
        return json.items;
    }

    /**
     * Displays marker on map and centers view on it for given address. Queries mapy.cz API for coordinates.
     * @param map OpenLayers map object to add marker to
     * @param address address to geocode to coordinates
     * @param zoom zoom level 1-16 (defaults on 12)
     */
    public async showAddressOnMap(
        map: Map,
        address: Address,
        zoom = 12,
    ): Promise<void> {
        const result =
            address.location != null
                ? [address]
                : await this.geocodeAddress(address);
        const markers: Feature<Point>[] = result.map(
            (item: Address) =>
                new Feature(
                    new Point(
                        fromLonLat([item.position!.lon, item.position!.lat]),
                    ),
                ),
        );

        const markerLayer = this.getOrCreateMarkerLayer(map);
        markerLayer.getSource()!.clear();
        markerLayer.getSource()!.addFeatures(markers);
        if (markers.length > 0) {
            map.getView().setCenter(markers[0].getGeometry()!.getCoordinates());
            map.getView().setZoom(zoom);
        }
    }

    /**
     * Create marker on map for given address. Queries mapy.cz API for coordinates.
     * @param map OpenLayers map object to add marker to
     * @param address address to geocode to coordinates
     * @param clear clear all markers before adding new one
     */
    public async createAddressMarker(
        map: Map,
        address: Address,
        clear = false,
    ): Promise<void> {
        const markerLayer = this.getOrCreateMarkerLayer(map);
        const result =
            address.location != null
                ? [address]
                : await this.geocodeAddress(address);
        const markers: Feature<Point>[] = result.map(
            (item: Address) =>
                new Feature(
                    new Point(
                        fromLonLat([item.position!.lon, item.position!.lat]),
                    ),
                ),
        );
        if (clear) {
            markerLayer.getSource()!.clear();
        }
        markerLayer.getSource()!.addFeatures(markers);
    }

    private async geocodeAddress(address: Address): Promise<Address[]> {
        const url = new URL(environment.mapGeocodeUrl);
        url.searchParams.append(
            'query',
            address.name + ', ' + address.location,
        );
        url.searchParams.append('limit', '1');
        url.searchParams.append('lang', 'cs');
        [
            'regional.municipality',
            'regional.municipality_part',
            'regional.street',
            'regional.address',
        ].forEach((type) => url.searchParams.append('type', type));

        // TODO: catch errors
        const response = await fetch(url.toString(), {
            mode: 'cors',
        });
        const json = await response.json();

        return json.items;
    }

    /**
     * Shows popup with address details on map for given coordinates.
     * @param map map object (reference)
     * @param lon longitude
     * @param lat latitude
     * @returns Promise of one address or none
     */
    public async createAddressPopup(
        map: Map,
        address: Address | null,
        coordinates: Coordinate,
    ): Promise<void> {
        let html = '';
        if (address) {
            html += `<p>${address.name}</p>`;
        } else {
            html = '<p>No results found.</p>';
        }

        const overlay = this.getOrCreatePopupOverlay(map);
        const popup = overlay.getElement()!;
        popup.innerHTML = html;
        overlay.setPosition(coordinates);
    }

    private async reverseGeocode(coords: Coordinate): Promise<Address[]> {
        const url = new URL(environment.mapReverseGeocodeUrl);
        const [lon, lat] = toLonLat(coords);
        url.searchParams.append('lon', `${lon}`);
        url.searchParams.append('lat', `${lat}`);

        // TODO: catch errors
        const response = await fetch(url.toString(), {
            mode: 'cors',
        });
        const json = await response.json();

        return json.items;
    }

    /**
     * Registers click event on map to show address popup. Callback can be used to handle address object parsing.
     * @param map map object (reference)
     * @param updateFnc callback to handle address object parsing
     */
    public registerMapClickAddressHandler(
        map: Map,
        updateFnc: (
            address: Address,
            coordinates: Coordinate,
        ) => void = () => {},
        failureFnc: (coordinates: Coordinate) => void = () => {},
    ): void {
        map.on('click', async (event: MapBrowserEvent<PointerEvent>) => {
            const addresses = await this.reverseGeocode(event.coordinate);
            if (addresses.length > 0) {
                updateFnc(addresses[0], event.coordinate);
            } else {
                failureFnc(event.coordinate);
            }
        });
    }

    /**
     * Creates route on map between two addresses.
     * @param map map object (reference)
     * @param start start address
     * @param end end address
     * @param clear clear all routes before adding new one
     * @returns Promise of route length and duration
     */
    public async createRoute(
        map: Map,
        start: Address,
        end: Address,
        clear = false,
        fit = true,
    ): Promise<{ length: number | null; duration: number | null }> {
        const routeLayer = this.getOrCreateRouteLayer(map);
        if (clear) {
            routeLayer.getSource()!.clear();
        }

        const path = await this.getPath(start, end);
        if (path.geometry !== null) {
            const polyline = new Polyline({
                factor: 1e5,
            }).readGeometry(path.geometry, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857',
            });

            const feature = new Feature({
                type: 'route',
                geometry: polyline,
            });

            routeLayer.getSource()!.addFeature(feature);
            if (fit) {
                map.getView().fit(routeLayer.getSource()!.getExtent(), {
                    maxZoom: 14,
                    padding: [-10, -10, -10, -10],
                });
            }
        }

        return {
            length: path.length,
            duration: path.duration,
        };
    }

    /**
     * Queries Mapy.cz API for route between two addresses.
     * Use rather createRoute() method to display route on map while getting the same parameters.
     * @param start start address
     * @param end end address
     * @returns RoutingResponse object with route length, expected duration and polyline geometry
     */
    public async getPath(
        start: Address,
        end: Address,
    ): Promise<RoutingResponse> {
        const url = new URL(environment.mapRoutingUrl);
        url.searchParams.append(
            'start',
            `${start.position.lon},${start.position.lat}`,
        );
        url.searchParams.append(
            'end',
            `${end.position.lon},${end.position.lat}`,
        );
        url.searchParams.append('routeType', 'car_short');
        url.searchParams.append('format', 'polyline');

        try {
            const response = await fetch(url.toString(), {
                mode: 'cors',
            });
            const json = (await response.json()) as RoutingResponse;

            return {
                length: Math.ceil((json.length || 0) / 1_000),
                duration: Math.ceil((json.duration || 0) / 60),
                geometry: json.geometry,
            };
        } catch (e) {
            return { length: null, duration: null };
        }
    }

    private getOrCreateMarkerLayer(map: Map): VectorLayer<Vector> {
        let markerLayer = map
            .getLayers()
            .getArray()
            .find(
                (layer) => layer.get('name') === 'markerLayer',
            ) as VectorLayer<Vector>;

        if (markerLayer == null) {
            markerLayer = new VectorLayer({
                source: new Vector(),
                style: new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        src: 'https://api.mapy.cz/img/api/marker/drop-red.png',
                    }),
                }),
            });
            markerLayer.set('name', 'markerLayer');
            map.addLayer(markerLayer);
        }

        return markerLayer;
    }

    private getOrCreateRouteLayer(map: Map): VectorLayer<Vector> {
        let routeLayer = map
            .getLayers()
            .getArray()
            .find(
                (layer) => layer.get('name') === 'routeLayer',
            ) as VectorLayer<Vector>;

        if (routeLayer == null) {
            routeLayer = new VectorLayer({
                source: new Vector(),
                style: new Style({
                    stroke: new Stroke({ color: '#777', width: 5 }),
                }),
            });
            routeLayer.set('name', 'routeLayer');
            map.addLayer(routeLayer);
        }

        return routeLayer;
    }

    private getOrCreatePopupOverlay(map: Map) {
        let overlay = map
            .getOverlays()
            .getArray()
            .find((overlay) => overlay.get('name') === 'popup') as Overlay;

        if (overlay == null) {
            const element = document.createElement('div');
            element.style.backgroundColor = 'var(--surface-ground)';
            element.style.borderRadius = '1em';
            element.style.paddingLeft = '1em';
            element.style.paddingRight = '1em';
            overlay = new Overlay({
                element: element,
                positioning: 'bottom-center',
                offset: [0, -10],
                autoPan: true,
            });
            overlay.set('name', 'popup');
            map.addOverlay(overlay);
        }

        return overlay;
    }
}

export interface Address {
    label?: string; // "Adresa"
    location: string; // "Praha 1 - Nové Město, Česko"
    name: string; // "Václavské náměstí 834/17"

    // { lon: 14.4258, lat: 50.08298 }
    position: { lon: number; lat: number };

    // { name: "834/17", type: "regional.address" }
    // { name: "Václavské náměstí", type: "regional.street" }
    // { name: "Nové Město", type: "regional.municipality_part" }
    // { name: "Praha 1", type: "regional.municipality_part" }
    // { name: "Praha", type: "regional.municipality" }
    // { name: "okres Hlavní město Praha", type: "regional.region" }
    // { name: "kraj Hlavní město Praha", type: "regional.region" }
    regionalStructure: { name: string; type: string }[];

    // "regional.address"
    type?: string;

    // only on regional.address type
    zip: string | null; // "110 00"
}

export enum AddressRegionalStructure {
    houseNumber = 'regional.address',
    street = 'regional.street',
    cityPart = 'regional.municipality_part',
    city = 'regional.municipality',
    region = 'regional.region',
}

export interface RoutingResponse {
    length: number | null;
    duration: number | null;
    // string is either in polyline or polyline6 format
    geometry?:
        | string
        | {
              type: string;
              geometry: { type: string; coordinates: [[number]] };
              properties: object;
          };
}
