<div class="w-full">
    <p-button
        *ngIf="!formValue"
        class="w-full"
        label="{{ buttonLabel | translate }}"
        (onClick)="showExistingSelect()"
    ></p-button>
    <div
        *ngIf="formValue"
        class="flex w-full"
    >
        <span class="font-bold align-self-center mx-3">
            {{ formValue? selectedExistingName : '' }}
        </span>
        <p-button
            *ngIf="formValue"
            icon="pi pi-times"
            styleClass="p-button-rounded p-button-danger p-button-text"
            (onClick)="onExistingSelected(null)"
        ></p-button>
    </div>
</div>
<p-dialog
    header="{{ dialogHeader | translate }}"
    [(visible)]="selectExistingDialogVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{maxWidth: '1300px', minWidth: '400px'}"
    class="dropdown-menu-visible"
>
    <div class="mb-5"></div>
    <app-input-select-entity
        [options]="options"
        [valueKey]="valueKey"
        [nameKey]="nameKey"
        [selectLabelPrefix]="selectLabelPrefix"
        [selectButtonLabel]="selectButtonLabel"
        [cancelButtonLabel]="cancelButtonLabel"
        (entitySelectedEvent)="onExistingSelected($event)"
        (cancelEvent)="onExistingSelectCancel()"
    ></app-input-select-entity>
</p-dialog>
