<span [formGroup]="form" class="w-full"
      [ngClass]="[icon ? 'p-input-icon-right' : '', label ? 'p-float-label' : '', showArrows ? '' : 'hideArrows']">
  <i *ngIf="icon" [className]="'pi ' + icon"></i>
      <input
          #input
          [formControlName]="id"
          [type]="type"
          [placeholder]="placeholder || '' | translate"
          pInputText
          class="w-full"
          [ngClass]="{ 'p-disabled': disabled, 'border-primary border-1': markAsRequired }"
          [autocomplete]="autocomplete ? 'on' : 'off'"
          [disabled]="disabled"
          [readonly]="readonly"
          [min]="min"
          [max]="max"
      />
      <label *ngIf="!hideLabel && label" [for]="id">{{ label | translate }}</label>
</span>
<app-required-error [form]="form" [id]="id"></app-required-error>
<app-invalid-email [form]="form" [id]="id"></app-invalid-email>
<app-min-value-error [form]="form" [id]="id"></app-min-value-error>
<app-max-value-error [form]="form" [id]="id"></app-max-value-error>
<app-min-length-error [form]="form" [id]="id"></app-min-length-error>
<app-max-length-error [form]="form" [id]="id"></app-max-length-error>
<app-batch-number-exists [form]="form" [id]="id"></app-batch-number-exists>
<app-invoice-number-exists [form]="form" [id]="id"></app-invoice-number-exists>
<app-only-numbers-error [form]="form" [id]="id"></app-only-numbers-error>
