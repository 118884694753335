import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-input-select-entity',
    templateUrl: './input-select-entity.component.html',
    styleUrls: ['./input-select-entity.component.scss'],
})
export class InputSelectEntityComponent {
    @Input() options: Record<string, string | number>[] = [];
    @Input() valueKey = 'id';
    @Input() nameKey = 'name';
    @Input() noOptionsLabel = 'inputs.selectUserForm.noOptions';
    @Input() selectLabelPrefix = 'inputs.selectUserForm.';
    @Input() selectButtonLabel = 'inputs.select';
    @Input() cancelButtonLabel = 'inputs.cancel';
    @Output() entitySelectedEvent = new EventEmitter<string | number | null>();
    @Output() cancelEvent = new EventEmitter<void>();

    form = this.fb.group({
        entity: this.fb.control<number | string | null>(null),
    });

    constructor(
        private fb: FormBuilder,
    ) {
    }

    submit() {
        this.entitySelectedEvent.emit(this.form.value.entity);
    }

    hide() {
        this.cancelEvent.emit();
    }
}
