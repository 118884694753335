import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-input-text-area',
    templateUrl: './input-text-area.component.html',
})
export class InputTextAreaComponent implements OnInit {
    @Input() label = '';
    @Input({ required: true }) form!: FormGroup;
    @Input() id = 'text';
    @Input() rows = 5;

    public ngOnInit(): void {
        if (this.label == '') {
            this.label = `inputs.${this.id}`
        }
    }

    get markAsRequired(): boolean {
        const formField = this.form.get(this.id);
        return !!formField && formField.hasValidator(Validators.required) && !formField.value && formField.pristine;
    }
}
