import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
  standalone: true,
})
export class AgePipe implements PipeTransform {
  public transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }

    const monthDiff = Date.now() - (new Date(value)).getTime();
    const years = new Date(monthDiff);
    const year = years.getUTCFullYear();

    return Math.abs(year - 1970);
  }

}
