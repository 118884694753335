import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimePeriodEnum } from '../../../../content/enum/services/timePeriod.enum';
import { SubscriptionComponent } from '../../../helpers/subscription.component';

@Component({
    selector: 'app-input-date-scroller',
    templateUrl: 'input-date-scroller.component.html',
    styleUrls: ['input-date-scroller.component.scss'],
})
export class InputDateScrollerComponent extends SubscriptionComponent implements OnChanges {
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;
    @Input() endDateId!: string;
    @Input() period = TimePeriodEnum.Day;
    @Input() enablePeriodChange = false;
    value: Date = new Date();

    periodOptions = [
        { key: TimePeriodEnum.Day, value: TimePeriodEnum.Day },
        { key: TimePeriodEnum.Week, value: TimePeriodEnum.Week },
        { key: TimePeriodEnum.Month, value: TimePeriodEnum.Month },
    ]

    periodForm = this.fb.group({
        periodLength: this.fb.control<number>(1, [Validators.required, Validators.min(1)]),
        period: this.fb.control<TimePeriodEnum>(TimePeriodEnum.Day),
    })

    constructor(
        private readonly fb: FormBuilder,
    ) {
        super();
        this.subs.push(
            this.periodForm.valueChanges.subscribe((newValues) => {
                this.addDays(0);
            }),
        )
    }

    addDays(days: number, date: Date | null = null) {
        const current = new Date(date ?? this.form.value[this.id]);

        let addDays = days;
        switch (this.periodForm.value.period) {
            case TimePeriodEnum.Day:
                break;
            case TimePeriodEnum.Week:
                addDays *= 7;
                break;
            case TimePeriodEnum.Month:
                current.setDate(1);
                if (addDays > 0) {
                    for (let i = 0; i < addDays; i++) {
                        current.setDate(40);
                        current.setDate(1);
                    }
                } else if (addDays < 0) {
                    for (let i = 0; i > addDays; i--) {
                        current.setDate(-1);
                        current.setDate(1);
                    }
                }
                addDays = 0;
                break;
        }

        current.setDate(current.getDate() + addDays);

        this.getEndOfPeriod(current);
    }

    select(event: Date) {
        this.getEndOfPeriod(event);
    }

    getEndOfPeriod(startDate: Date) {
        const periodLength = Math.max(Number(this.periodForm.value.periodLength ?? 1), 1);
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        let endDate = new Date(startDate);

        switch (this.periodForm.value.period) {
            case TimePeriodEnum.Day:
                endDate.setDate(start.getDate() + periodLength - 1);
                break;
            case TimePeriodEnum.Week:
                start.setDate(start.getDate() - start.getDay() + 1)
                this.form.patchValue({
                    [this.id]: start,
                });
                endDate = new Date(start);
                endDate.setDate(endDate.getDate() + 6 + 7 * (periodLength - 1));
                break;
            default:
                start.setDate(1);
                endDate = new Date(start);
                for (let i = 0; i < periodLength; i++) {
                    endDate.setDate(endDate.getDate() + 40);
                    endDate.setDate(0);
                }
                break;
        }

        endDate.setHours(23, 59, 59, 999);

        this.form.patchValue({
            [this.id]: start,
            [this.endDateId]: endDate,
        });
    }

    ngOnChanges(): void {
        this.getEndOfPeriod(this.form.value[this.id]);
        this.periodForm.patchValue({
            period: this.period,
        });
        this.addDays(0);
    }

    protected readonly TimePeriodEnum = TimePeriodEnum;
}
