<ng-container *ngIf="template; else defaultTemplate">
    <ng-container
        *ngTemplateOutlet="template; context: { data: value, item: item, rowIndex: rowIndex }">
    </ng-container>
</ng-container>

<ng-template #defaultTemplate>
    <ng-container [ngSwitch]="type">
        <div *ngSwitchCase="TableColumnType.Date">{{ value | date: 'd.M.y' }}</div>
        <div *ngSwitchCase="TableColumnType.Age">{{ value | age }}</div>
        <div *ngSwitchCase="TableColumnType.Year">{{ value | year }}</div>
        <div *ngSwitchCase="TableColumnType.Tag">
            <p-tag *ngIf="value" [severity]="column.tagOptions.severity(value)"
                [value]="column.tagOptions.labelPrefix + value | translate" [rounded]="true"></p-tag>
        </div>
        <app-user-chip *ngSwitchCase="TableColumnType.UserChip"
            [user]="column.userChipOptions?.dataCallback(value)"
            [renderType]="column.userChipOptions?.renderType"></app-user-chip>
        <div *ngSwitchDefault>
            {{ value }}
        </div>
    </ng-container>
</ng-template>
