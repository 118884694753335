import { LanguageEnum } from '../../shared/models/language.enum';
import { UserSettingsObject } from '@it2go/types';

export const layoutFeatureKey = 'layout';

export interface LayoutState {
    userSettings: UserSettingsObject;
    groupId: string;
    backendUrl: string;
}

export const createInitialLayoutState = (): LayoutState => ({
    userSettings: {
        language: LanguageEnum.CZECH as string,
        colorScheme: 'light',
        menuType: 'slim',
        menuTheme: 'darkgray',
        componentTheme: 'blue',
        scale: 14,
        inputStyle: 'outlined',
        floatLabelScheme: 'md-float-label',
        ripple: true,
        showAllMessages: false,
    },
    groupId: '1',
    backendUrl: '',
});
