<div class="{{ styleClass }}" [formGroup]="form">
  <span class="flex flex-row add-icon-to-input w-full">
    <span class="p-float-label w-full">
      <p-calendar
        [formControlName]="id"
        [id]="id"
        appendTo="body"
        class="w-full"
        styleClass="w-full custom-calendar-icon"
        [ngClass]="{ 'required-input-border': markAsRequired }"
        dateFormat="{{ 'calendar.dateFormat' | translate }}"
        [firstDayOfWeek]="1"
        [showButtonBar]="showButtonBar && !timeOnly"
        [todayButtonStyleClass]="'p-button-text label-bold'"
        [clearButtonStyleClass]="'p-button-text label-bold'"
        [showTime]="showTime"
        [timeOnly]="timeOnly"
        [readonlyInput]="disabled"
      ></p-calendar>
      <label *ngIf="!hideLabel" [for]="id">{{ label | translate }}</label>
    </span>
    <i *ngIf="!timeOnly" class="pi pi-calendar"></i>
    <i *ngIf="timeOnly" class="pi pi-clock"></i>
  </span>
  <app-required-error [form]="form" [id]="id"></app-required-error>
</div>
