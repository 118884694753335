<div [formGroup]="form" class="{{ styleClass }}">
    <div *ngIf="showTitle" class="font-bold fr-2 align-self-center">
        {{ translatePrefix + '.title' | translate }}:
    </div>
    <div class="fr-10 flex">
        <p-button
            *ngIf="!form.value.address; else addressFilledIn"
            type="button"
            icon="pi pi-home"
            class="mr-2"
            styleClass="p-button-primary"
            pTooltip="{{
                translatePrefix + '.selectAddressButtonTooltip' | translate
            }}"
            (click)="showMap()"
        ></p-button>
        <ng-template #addressFilledIn>
            <p-button
                icon="pi pi-map"
                class="mr-2"
                styleClass="p-button-info p-button-text"
                pTooltip="{{
                    translatePrefix + '.showOnMapButtonTooltip' | translate
                }}"
                (click)="showMap()"
            ></p-button>
        </ng-template>

        <app-input-text-autocomplete
            [id]="id"
            [form]="form"
            [forceSelection]="true"
            [suggestions]="suggestions"
            field="name"
            [suggestFnc]="suggestAddressesBound"
            [label]="translatePrefix + '.label' | translate"
            class="w-full"
        >
            <ng-template let-address pTemplate="item">
                <div class="flex flex-row">
                    <span>{{ address.name + ', ' + address.location }}</span>
                </div>
            </ng-template>
        </app-input-text-autocomplete>
    </div>

    <p-dialog
        [(visible)]="displayMap"
        [modal]="true"
        [baseZIndex]="10000"
        [draggable]="true"
        [resizable]="true"
        [closable]="true"
        appendTo="body"
    >
        <div class="content input-grid-gaps">
            <app-map #map [map]="mapObject" />
            <div class="dialogButtons">
                <app-save-button [disabled]="!mapClickAddress" (clickEvent)="confirmAddress()"></app-save-button>
                <app-cancel-button (clickEvent)="showMap(false)"></app-cancel-button>
            </div>
        </div>
    </p-dialog>
</div>
