<span
  [formGroup]="form"
  class="w-full"
  [ngClass]="[icon ? 'p-input-icon-right' : '', label ? 'p-float-label' : '']"
>
  <i *ngIf="icon" [className]="'pi ' + icon"></i>
  <p-autoComplete
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder || ''"
    [suggestions]="suggestions"
    [field]="field || ''"
    (completeMethod)="suggestFnc($event)"
    [forceSelection]="forceSelection"
    class="w-full"
    [ngClass]="{ 'required-input-border': markAsRequired }"
    appendTo="body"
  >
    <ng-template *ngIf="optionTemplate" let-item pTemplate="item">
      <div class="flex flex-row">
        <ng-container
          pTemplate="item"
          *ngTemplateOutlet="optionTemplate; context: { $implicit: item }"
        ></ng-container>
      </div>
    </ng-template>
  </p-autoComplete>
  <label *ngIf="label" [for]="id">{{ label | translate }}</label>
</span>
<app-required-error [form]="form" [id]="id"></app-required-error>
