export enum BreadcrumbsEnum {
    Crm = 'crm',
    Content = 'content',
    DataBoxes = 'databoxes',
    Database = 'database',
    Donors = 'donors',
    Donations = 'donations',
    Collections = 'collections',
    Messages = 'messages',
    CreateMessage = 'createMessage',
    Organizations = 'organizations',
    Organization = 'organization',
    CreateOrganization = 'createOrganization',
    Settings = 'settings',
    Services = 'services',
    Service = 'service',
    ServiceBasicInfo = 'serviceBasicInfo',
    ServiceSettings = 'serviceSettings',
    ServiceSettingsGeneralInfo = 'serviceSettingsGeneralInfo',
    ServiceSettingsForms = 'serviceSettingsForms',
    ServiceGroups = 'serviceGroups',
    Users = 'users',
    User = 'user',
    Groups = 'groups',
    Center = 'center',
    Folders = 'folders',
    CreatePerson = 'createPerson',
    Folder = 'folder',
    FolderEdit = 'folderEdit',
    FolderDashboard = 'dashboard',
    FolderMedications = 'folderMedications',
    FolderEpicrisis = 'epicrisis',
    FolderServiceRecord = 'serviceRecord',
    FolderNurseRecords = 'nurseRecords',
    PersonContacts = 'contacts',
    FolderVouchers = 'vouchers',
    Documents = 'documents',
    FolderVoucher = 'voucher',
    Works = 'works',
    RealizedWorks = 'realizedWork',
    WorkVisit = 'workVisit',
    WorkVisits = 'workVisits',
    Billing = 'billing',
    BillingNewCreate = 'billingNewCreate',
    BillingNewConfirm = 'billingNewConfirm',
    BillingRefused = 'billingRefused',
    BillingUnbilledWork = 'billingUnbilledWork',
    BillingAccountingOutput = 'billingAccountingOutput',
    BillingCreatedBillings = 'billingCreatedBillings',
    BillingCreatedBillingsDetail = 'billingCreatedBillingsDetail',
    BillingUnprocessedBillings = 'billingUnprocessedBillings',
    BillingInvoiceDetail = 'billingInvoiceDetail',
    BillingBatchDetail = 'billingBatchDetail',
    BillingDocumentDetail = 'billingDocumentDetail',
    Workplace = 'workplace',
    FilesManager = 'filesManager',
}
