import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { SubscriptionComponent } from '../../../helpers/subscription.component';

@Component({
    selector: 'app-input-select-button',
    templateUrl: './input-select-button.component.html',
    styleUrls: ['./input-select-button.component.scss'],
})
export class InputSelectButtonComponent extends SubscriptionComponent implements OnChanges {

    constructor(
        protected translate: TranslateService,
    ) {
        super();
        this.subs.push(this.translate.onLangChange.subscribe(() => {
            this.translateKeys();
        }));
    }

    @Input() options: { key: string, value: string }[] = [];
    @Input() id = 'selectButton';
    @Input({ required: true }) form!: FormGroup;
    @Input() prefix = 'inputs.';

    optionsTranslated: { key: string, value: string }[] = [];

    ngOnChanges() {
        this.translateKeys();
    }

    translateKeys() {
        this.optionsTranslated = this.options.map((it) => ({
            value: it.value,
            key: this.translate.instant(`${this.prefix}${it.key}`),
        }));
    }

}
