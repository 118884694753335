export const userTableFrag = `
    userId
    table
    filter
    columns
    columnSettings
`;

export const userTableGridItemFrag = `
    userId
    table
    filter
    columns
    columnSettings
`;
