<form [formGroup]="form">
    <div *ngIf="tooManyValues" class="mb-2 text-danger">{{ 'common.tooManyResults' | translate }}</div>
    <p-multiSelect
        [options]="options"
        [formControlName]="id"
        [optionLabel]="nameKey"
        [optionValue]="valueKey"
        [placeholder]="label"
        [display]="display"
        [disabled]="disabled"
        [filter]="shouldFilter"
        [filterBy]="$any(filterBy)"
        [appendTo]="appendToBody ? 'body' : ''"
        (onFilter)="filterCallback($event.filter)"
        [showClear]="showClear"
        [emptyFilterMessage]="'common.noResults' | translate"
    >
        <ng-container *ngIf="itemTemplate">
            <ng-template pTemplate="item" let-item>
                <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="!itemTemplate">
            <ng-template pTemplate="item" let-item>
                {{ namePrefix + item[nameKey] | translate }}
            </ng-template>
        </ng-container>

        <ng-container *ngIf="selectedItemsTemplate">
            <ng-template pTemplate="selectedItems" let-items>
                <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: { items }"></ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="!selectedItemsTemplate">
            <ng-template pTemplate="selectedItems" let-item>
                <ng-container *ngIf="item">
                    {{ namePrefix + item[nameKey] | translate }}
                </ng-container>
            </ng-template>
        </ng-container>
</p-multiSelect>
</form>
<app-required-error [form]="form" [id]="id"></app-required-error>
