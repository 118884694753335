import { GridInput } from '@it2go/data-grid';

export const sharedFeatureKey = 'shared';

export interface ColumnSetting {
    size: number;
    visible: boolean;
}

export interface TableSetting {
    filter: GridInput | null,
    columns: string[],
    columnSettings: Record<string, ColumnSetting>,
}

export interface SharedState {
    tableSettings: Record<string, TableSetting>,
    tableSettingsFetched: boolean,
    tableRefresher: Record<string, string[]>,
}

export function createInitialSharedState(): SharedState {
    return {
        tableSettings: {},
        tableRefresher: {},
        tableSettingsFetched: false,
    };
}
