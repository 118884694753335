import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-only-numbers-error',
    templateUrl: './only-numbers-error.component.html',
})
export class OnlyNumbersErrorComponent {
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;
}