import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputTextComponent } from './input-text.component';

@Component({
    selector: 'app-input-email',
    templateUrl: 'input-text.component.html',
})
export class InputEmailComponent extends InputTextComponent {
    @Input() override label = 'inputs.email';
    @Input() override id = 'email';
    @Input({ required: true }) override form!: FormGroup;
    @Input() override icon = 'pi-envelope';
    @Input() override placeholder: string | null = null;
    @Input() override autocomplete = true;
    @Input() override disabled = false;
}
