<p-button
    *ngIf="!linkText"
    icon="pi pi-map"
    styleClass="p-button-info p-button-text"
    pTooltip="{{ tooltip | translate }}"
    (click)="showOnMap($any(data))"
></p-button>
<p-button
    *ngIf="linkText"
    [link]="true"
    pTooltip="{{ tooltip | translate }}"
    (click)="showOnMap($any(data))"
>{{linkText}}</p-button>

<!--map modal-->
<p-dialog
    appendTo="body"
    [(visible)]="displayMap"
    [style]="{ width: '50vw', height: '50vh' }"
    [baseZIndex]="10000"
    [resizable]="true"
    [closable]="true"
>
    <app-map #map [map]="mapObject" />
</p-dialog>
