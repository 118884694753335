import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ListboxChangeEvent } from 'primeng/listbox';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-input-fulltext',
    templateUrl: 'input-fulltext.component.html',
    styleUrls: ['./input-fulltext.component.scss'],
})
export class InputFulltextComponent implements OnInit, OnDestroy {
    @Input() label = '';
    @Input() options = [];
    @Input({ required: true }) form!: FormGroup;
    @Output() selectedOptionChange = new EventEmitter<IOption | null>();

    subs: Subscription[] = [];

    searchOptions = [];

    selectedOptionSearch: object | null = null;

    selectedOptionSearchData: string = '';

    ngOnInit(): void {
        this.subs.push(
            this.form.valueChanges.subscribe(({ search }) => {
                this.selectedOptionSearch = null;
                this.selectedOptionSearchData = '';

                let found = false;

                this.searchOptions = this.options.filter((item: IOption) => {
                    if (
                        search &&
                        item.data.toLowerCase().includes(search.toLowerCase())
                    ) {
                        if (!found) {
                            this.selectedOptionSearchData = item.data.replace(
                                new RegExp(search, 'gi'),
                                (match) => {
                                    return `<b>${match}</b>`;
                                },
                            );
                            this.selectedOptionSearch = item;
                            found = true;
                        }

                        return true;
                    }

                    return false;
                });
            }),
        );
    }

    accept(): void {
        this.selectedOptionChange.emit(this.selectedOptionSearch as IOption);
    }

    listSearchChange(index: ListboxChangeEvent): void {
        this.selectedOptionSearch = this.options[index.value.index];
        this.selectedOptionSearchData = (
            this.options[index.value.index]['data'] as string
        ).replace(
            new RegExp(this.form.value.search as string, 'gi'),
            (match) => {
                return `<b>${match}</b>`;
            },
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
    }
}

export interface IOption {
    data: string;
}
