import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-input-switch',
    templateUrl: 'input-switch.component.html',
})
export class InputSwitchComponent {

    @Input() textStyleClass = '';
    @Input() label = '';
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;
    @Input() disabled: boolean = false;

    switch(): void {
        this.form.patchValue({
            [this.id]: !this.form.value[this.id],
        });
    }

}
