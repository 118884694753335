import { addressFrag } from './common.types';

export const employmentFrag = `
    employmentTypeName
    id
    organizationId
    serviceId
    size
`;

export const userGridItemFrag = `
    address {
        ${addressFrag}
    }
    email
    id
    mobile
    name
    phone
    surname
    titleAfterName
    titleBeforeName
    icpOfDoctor
    doctor
    employments {
        ${employmentFrag}
    }
    role {
        id
        name
    }
    active
`;

export const defaultAppConfig = {
    language: 'cz',
    ripple: true,
    inputStyle: 'outlined',
    menuType: 'static',
    colorScheme: 'light',
    floatLabelScheme: 'md-float-label',
    theme: 'blue',
    scale: 14,
    menuTheme: 'darkgray',
    componentTheme: 'blue',
};

export const defaultMenuThemes = [
    {
        name: 'white',
        color: '#ffffff',
        logoColor: 'dark',
        componentTheme: null,
    },
    {
        name: 'darkgray',
        color: '#343a40',
        logoColor: 'white',
        componentTheme: null,
    },
    {
        name: 'blue',
        color: '#2196F3',
        logoColor: 'dark',
        componentTheme: 'blue',
    },
    {
        name: 'bluegray',
        color: '#455a64',
        logoColor: 'white',
        componentTheme: 'lightgreen',
    },
    {
        name: 'brown',
        color: '#5d4037',
        logoColor: 'white',
        componentTheme: 'cyan',
    },
    {
        name: 'cyan',
        color: '#00BCD4',
        logoColor: 'dark',
        componentTheme: 'cyan',
    },
    {
        name: 'green',
        color: '#4CAF50',
        logoColor: 'dark',
        componentTheme: 'green',
    },
    {
        name: 'indigo',
        color: '#3F51B5',
        logoColor: 'white',
        componentTheme: 'indigo',
    },
    {
        name: 'deeppurple',
        color: '#673AB7',
        logoColor: 'white',
        componentTheme: 'deeppurple',
    },
    {
        name: 'orange',
        color: '#FF9800',
        logoColor: 'dark',
        componentTheme: 'orange',
    },
    {
        name: 'pink',
        color: '#E91E63',
        logoColor: 'white',
        componentTheme: 'pink',
    },
    {
        name: 'purple',
        color: '#9C27B0',
        logoColor: 'white',
        componentTheme: 'purple',
    },
    {
        name: 'teal',
        color: '#009688',
        logoColor: 'dark',
        componentTheme: 'teal',
    },
];
