import { Component } from '@angular/core';
import { TableComponent } from './table.component';

@Component({
  selector: 'app-card-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class CardTableComponent extends TableComponent {
  override containerClasses = 'surface-0 shadow-4 p-3 pt-2 border-round';
}
