import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, withLatestFrom } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as globalActions from '../../../store/global/global.actions';
import { UserService } from '../service/user.service';
import { EffectFactory } from './common/effect.factory';
import * as actions from './shared.actions';
import { selectTableRefreshers } from './shared.selectors';

@Injectable()
export class SharedEffects {

    factory = new EffectFactory(this.actions$, actions, this.api).create();

    getUserTables$ = this.factory.getUserTables;

    refreshTable$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(globalActions.globalSuccess),
                withLatestFrom(this.store.select(selectTableRefreshers)),
                tap(([{ input: { key } }, refreshers]) => {
                        Object.keys(refreshers).forEach((table) => {
                            if (refreshers[table].includes(key)) {
                                this.store.dispatch(actions.refreshTable({ table }));
                            }
                        });
                    },
                ),
            ),
        { dispatch: false },
    );

    storeSetTableFilter$ = createEffect(() =>
            this.actions$.pipe(
                ofType(actions.setTableSetting),
                switchMap(({ table, filter, columns, columnSettings }) => {
                        if (!columns && !columnSettings) {
                            return EMPTY;
                        }

                        return this.api.upsertUserTable({
                            table,
                            filter: JSON.stringify(filter),
                            columns: JSON.stringify(columns),
                            columnSettings: JSON.stringify(columnSettings),
                        });
                    },
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly api: UserService,
    ) {
    }

}
