import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../../../services/session.service';
import { CzechHolidays } from 'czech-holidays';

@Pipe({
    name: 'localDate',
    standalone: true,
})
export class LocalDatePipe implements PipeTransform {
    constructor(private session: SessionService) {}

    transform(
        value: null | string | number | Date | undefined,
        format = 'medium',
    ): string | boolean {
        return localDate(value, this.session, format);
    }
}

export function localDate(
    value: null | string | number | Date | undefined,
    session: SessionService,
    format = 'medium',
): string | boolean {
    if (!value) {
        return '';
    }

    if (/^\d\d:\d\d$/.test(value.toString())) {
        return value.toString();
    }

    if (format === 'weekday') {
        const weekday = new Date(value).toLocaleString(session.locale, {
            weekday: 'long',
        });
        const weekdayFirstLetter = weekday[0].toUpperCase();
        const weekdayRest = weekday.slice(1);

        return weekdayFirstLetter + weekdayRest;
    }

    if (format === 'workday') {
        const date = new Date(value);

        if (
            date.getDay() === 0 ||
            date.getDay() === 6 ||
            CzechHolidays(date.getFullYear()).find((holiday) => {
                return (
                    holiday.d === date.getDate() &&
                    holiday.m === date.getMonth() + 1
                );
            })
        ) {
            return false;
        }

        return true;
    }

    return formatDate(value, format, session.locale);
}
