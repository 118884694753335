import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputSelectButtonComponent } from './input-select-button.component';

@Component({
    selector: 'app-input-select-button-sex',
    templateUrl: './input-select-button.component.html',
})
export class InputSelectButtonSexComponent extends InputSelectButtonComponent {

    @Input() override options = [{ key: 'male', value: 'male' }, { key: 'female', value: 'female' }];
    @Input() override id = 'sex';
    @Input({ required: true }) override form!: FormGroup;
    @Input() override prefix!: 'inputs.';

}
