import { animate, style, transition, trigger } from '@angular/animations';
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MessagesModule } from 'primeng/messages';

@Component({
    selector: 'app-floating-message-template',
    templateUrl: 'floating-message.component.html',
    styleUrls: ['./floating-message.component.scss'],
    animations: [
        trigger(
            'visibilityAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({ opacity: 0 }),
                        animate('0.5s ease',
                            style({ opacity: 1 })),
                    ],
                ),
                transition(
                    ':leave',
                    [
                        style({ opacity: 1 }),
                        animate('0.2s ease',
                            style({ opacity: 0 })),
                    ],
                ),
            ],
        ),
    ],
})
export class FloatingMessageComponent extends MessagesModule {

    @Input() severity = 'info';
    @Input() message: string | null = null;
    @Input() visible = true;
    @Input() closeable = true;
    @Output() visibleChange = new EventEmitter<boolean>();

    close() {
        this.visible = false;
        this.visibleChange.emit(false);
    }

}
