<ng-container>
    <div class="font-bold text-warning icon-size">
        <i class="pi pi-exclamation-triangle mr-3"></i>
        {{ label | translate }}
    </div>
    <div *ngIf="note" class="mt-3 text-wrap">
        {{ note | translate }}
    </div>
    <div class="dialogButtons button-label-bold mt-4">
        <app-save-button label="confirmDialog.confirmButton" [onClick]="emitConfirm.bind(this)"></app-save-button>
        <app-cancel-button [onClick]="emitCancel.bind(this)"></app-cancel-button>
    </div>
</ng-container>
