import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-invoice-number-exists',
    templateUrl: './invoice-number-exists.component.html',
})
export class InvoiceNumberExistsComponent {
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;

}