import { UserTableGridItemObject } from '@it2go/types';
import { createReducer, on } from '@ngrx/store';
import * as actions from './shared.actions';
import { createInitialSharedState, TableSetting } from './shared.state';

export const sharedReducer = createReducer(
    createInitialSharedState(),
    on(actions.setTableSetting,
        (state, newState) => {
            const newSetting = { ...(state.tableSettings[newState.table] || {}) };
            if (newState.filter) newSetting.filter = newState.filter;
            if (newState.columns) newSetting.columns = newState.columns;
            if (newState.columnSettings) newSetting.columnSettings = newState.columnSettings;

            return {
                ...state,
                tableSettings: {
                    ...state.tableSettings,
                    [newState.table]: newSetting,
                },
            };
        },
    ),

    on(actions.setTableRefresher, (state, newState) => ({
        ...state,
        tableRefresher: {
            ...state.tableRefresher,
            [newState.table]: newState.actions || [],
        },
    })),

    on(actions.getUserTablesSuccess, actions.getUserTablesFailure, (state, newState) => {
            return {
                ...state,
                tableSettingsFetched: true,
                tableSettings: (newState.userTables?.['items'] || []).reduce((acc: Record<string, TableSetting>, it: UserTableGridItemObject) => {
                    acc[it.table] = {
                        filter: JSON.parse(it.filter || '{}'),
                        columns: JSON.parse(it.columns || '[]'),
                        columnSettings: JSON.parse(it.columnSettings || '{}'),
                    };
                    return acc;
                }, {}),
            };
        },
    ),
);
