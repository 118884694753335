import { FilterInput, GridInput } from '@it2go/data-grid';

export function gridGqlInput(input: GridInput | null, additionalFilter: FilterInput[][] | null = null): GridInput {
    if (!input) {
        return {};
    }

    return {
        paging: input.paging ? {
            page: input.paging.page,
            itemsPerPage: input.paging.itemsPerPage,
        } : null,
        filter: input.filter ? input.filter.map((filters) => filters.map((item) => ({
            column: item.column,
            operator: item.operator,
            value: item.value,
        }))) : null,
        search: input.search,
        sorter: input.sorter ? input.sorter.map((sorter) => ({
            column: sorter.column,
            direction: sorter.direction,
        })) : null,
        additionalFilter,
    };
}

export const filterFrag = `
    paging {
        page
        itemsPerPage
        total
        lastPage
    }
    filter {
        column
        value
        operator
    }
    sorter {
        column
        direction
    }
`;
