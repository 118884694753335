// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql, QueryOptions } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type opts = Omit<QueryOptions, 'query' | 'variables'>;

@Injectable()
export abstract class GqlService {
    private regex = new RegExp(/\{\s*([a-zA-Z]+)\s*\{\s*([a-zA-Z]+)?/, 'gm');

    constructor(protected readonly apollo: Apollo) {}

    query<T>(
        query: string,
        variables: object = {},
        options: opts = {},
    ): Observable<T> {
        const [resource, method] = this.getFields(query);

        return this.apollo
            .query({
                query: gql`
                    ${query}
                `,
                variables,
                ...options,
            })
            .pipe(map((result) => result.data![resource][method]));
    }

    mutate<T>(mutation: string, variables: object = {}): Observable<T> {
        const [resource, method] = this.getFields(mutation);

        return this.apollo
            .mutate({
                mutation: gql`
                    ${mutation}
                `,
                variables,
            })
            .pipe(map((result) => result.data![resource][method]));
    }

    private getFields(query: string): string[] {
        return query.matchAll(this.regex).next().value.slice(1);
    }
}
