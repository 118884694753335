<div class="p-inputgroup">
    <span class="p-inputgroup-addon">
            <app-input-select
                class="phone-country-select"
                [options]="phoneOptions"
                [form]="phoneForm"
                id="country"
                [hideLabel]="true"
                valueKey="value"
            />
    </span>
    <span [formGroup]="form" class="w-full"
          [ngClass]="[icon ? 'p-input-icon-right' : '', label ? 'p-float-label' : '']">
            <i *ngIf="icon" [className]="'pi ' + icon"></i>
                <p-inputMask
                    [formControlName]="id"
                    [id]="id"
                    class="w-full"
                    [mask]="$any(phoneForm.controls.country.value)"
                ></p-inputMask>
                <label *ngIf="label" [for]="id">{{ label | translate }}</label>
        </span>
</div>
<app-required-error [form]="form" [id]="id"></app-required-error>
