import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-max-length-error',
    templateUrl: './max-length-error.component.html',
})
export class MaxLengthErrorComponent {
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;
}