import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateOr',
    standalone: true,
})
export class TranslateOrPipe implements PipeTransform {

    constructor(
        private readonly translate: TranslateService,
    ) {
    }

    public transform(value: string, ...args: any[]): any {
        if (!value) {
            return '';
        }

        let translated = this.translate.instant(value);
        if (translated == value) {
            translated = this.translate.instant(`fallbacks.${value.substring(value.lastIndexOf('.') + 1)}`);
        }

        return translated;
    }

}
