import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
} from '@angular/core';
import { Map, View } from 'ol';
import { fromLonLat } from 'ol/proj';
import { Control } from 'ol/control';
import { Tile } from 'ol/layer';
import { TileJSON } from 'ol/source';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent {
    /**
     * The map to display. Possibly with set view (center, zoom).
     */
    @Input()
    map: Map = new Map({
        view: new View({
            center: fromLonLat([15.4749126, 49.8037633]), // Center of Czech Republic
            zoom: 8, // +- whole Czech Republic
        }),
    });

    initialized = false;

    constructor(private elementRef: ElementRef) {}

    open() {
        if (this.initialized) return;
        this.initialized = true;

        this.map.setLayers([
            new Tile({
                source: new TileJSON({
                    url: environment.mapSourceUrl,
                }),
            }),
        ]);
        this.map.setTarget(this.elementRef.nativeElement);

        // Mapy.cz logo:
        const logo = new LogoControl();
        this.map.addControl(logo);
    }
}

// Mapy.cz logo as per their terms of use:
class LogoControl extends Control {
    constructor(options: { target?: HTMLElement } = {}) {
        const element = document.createElement('div');

        element.style.bottom = '0'; // angular mangles classes -> cannot use css file
        element.className = 'ol-unselectable ol-control';
        element.innerHTML =
            '<a href="http://mapy.cz/" target="_blank"><img src="https://api.mapy.cz/img/api/logo.svg" ></a>';

        super({
            element: element,
            target: options.target,
        });
    }
}
