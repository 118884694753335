import { createFeatureSelector, createSelector } from '@ngrx/store';
import { layoutFeatureKey, LayoutState } from './layout.state';

export const selectLayoutState =
  createFeatureSelector<LayoutState>(layoutFeatureKey);

export const selectUserSettings = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.userSettings,
);

export const selectGroupId = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.groupId,
);

export const selectBackendUrl = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.backendUrl,
);
