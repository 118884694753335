export function stripPrefix(name: string): string {
    let n = name;
    for (const prefix of ['getOrCreate', 'get', 'create', 'update', 'find', 'patch', 'prepare']) {
        if (name.startsWith(prefix)) {
            n = name.substring(prefix.length);
        }
    }

    return n.charAt(0).toLowerCase() + n.slice(1);
}
