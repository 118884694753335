import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../../../services/session.service';

@Pipe({
  name: 'year',
  standalone: true,
})
export class YearPipe implements PipeTransform {
  constructor(private session: SessionService) {}

  public transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }

    return formatDate(value, 'y', this.session.locale);
  }

}
