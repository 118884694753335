import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currentValid',
  standalone: true,
})
export class CurrentValidPipe implements PipeTransform {
  public transform(objects: { validTo?: Date|string|null }[]): any {
    return currentValid(objects);
  }

}

export function currentValid<T extends { validTo?: Date|string|null }>(objects?: T[]) {
  if (!objects) return null;

  let current = objects[0] || null;
  const now = new Date();
  for (const item of objects) {
    if (item.validTo != null && new Date(item.validTo) > now) {
      current = item;
    }
  }

  return current;
}
