import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatSize',
    standalone: true,
})
export class FormatSizePipe implements PipeTransform {
    transform(size: number): string {
        if (!size) {
            return '';
        }
        let unit = ' B';
        let actualSize = size;
        while (Math.floor((actualSize / 1024) * 10) / 10 > 1 && unit !== ' TB') {
            actualSize = Math.floor((actualSize / 1024) * 10) / 10;

            switch (unit) {
                case ' B':
                    unit = ' KB';
                    break;
                case ' KB':
                    unit = ' MB';
                    break;
                case ' MB':
                    unit = ' GB';
                    break;
                case ' GB':
                    unit = ' TB';
                    break;
                default:
                    break;
            }
        }

        return actualSize.toLocaleString('en', { useGrouping: true }) + unit;
    }
}
