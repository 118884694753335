<span *ngIf="typed !== 'select'" class="flex flex-row">

    <ng-container *ngIf="typed !== 'date'">
        <input
            id="text1"
            type="text"
            pInputText
            class="w-full"
            [(ngModel)]="value"
            (ngModelChange)="valueChanged()"
        />
        <input
            *ngIf="selectedOp === 'BETWEEN' || selectedOp === 'NBETWEEN'"
            id="text2"
            type="text"
            pInputText
            class="w-full"
            [(ngModel)]="value2"
            (ngModelChange)="valueChanged()"
        />
    </ng-container>
    <ng-container *ngIf="typed === 'date'">
        <p-calendar
            [(ngModel)]="date"
            [showButtonBar]="true"
            [firstDayOfWeek]="1"
            (ngModelChange)="updateDate(1, $event);"
            appendTo="body"
            dateFormat="dd.mm.yy"
        ></p-calendar>
    </ng-container>
    <!--  Date Range  -->
    <ng-container *ngIf="typed === 'date' && (selectedOp === 'BETWEEN' || selectedOp === 'NBETWEEN')">
        <p-calendar
            [(ngModel)]="date2"
            [showButtonBar]="true"
            [firstDayOfWeek]="1"
            (ngModelChange)="updateDate(2, $event);"
            appendTo="body"
            dateFormat="dd.mm.yy"
        ></p-calendar>
    </ng-container>

    <ng-container *ngIf="typed !== 'text-plain'">
    <p-overlayPanel #op>
        <ng-template pTemplate="content" class="filterBox">
            <p-listbox
                [options]="filterOps"
                [(ngModel)]="selectedOp"
                (ngModelChange)="valueChanged()"
                (onClick)="op.toggle($event)"
            >
                <ng-template let-item pTemplate="item">
                    {{ 'inputs.filters.' + item | translate }}
                </ng-template>
            </p-listbox>
        </ng-template>
    </p-overlayPanel>
    <p-button (click)="op.toggle($event)" icon="pi pi-filter"
              styleClass="p-button-rounded p-button-secondary p-button-text"></p-button>
    </ng-container>
</span>

<!-- Select filter -->
<span *ngIf="typed === 'select'">
    <p-dropdown
        appendTo="body"
        [options]="type"
        [(ngModel)]="value"
        (onChange)="valueChanged()"
        optionValue="value"
        optionLabel="name"
        [showClear]="true"
        styleClass="w-full">
        <ng-template pTemplate="item" let-item>
            {{ item.name | translate }}
        </ng-template>

        <ng-template pTemplate="selectedItem" let-item>
            {{ item.name | translate }}
        </ng-template>
    </p-dropdown>
</span>
