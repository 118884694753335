<ul>
    <li *ngFor="let item of items">
        {{ item.key | translate }}:
        <ng-container *ngIf="valueTemplate"
                      [ngTemplateOutlet]="valueTemplate"
                      [ngTemplateOutletContext]="{ $implicit: item.value }"></ng-container>
        <ng-container *ngIf="!valueTemplate">
            {{ item.value }}
        </ng-container>
    </li>
</ul>
