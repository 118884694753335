import { createAction, props } from '@ngrx/store';
import { GridInput } from '@it2go/data-grid';
import { CreateUserInput, UserGridObject, UserObject } from '@it2go/types';

// TODO remove
export const
    getUsers = createAction('getUsers', props<{ input: GridInput | null }>()),
    getUsersSuccess = createAction('getUsers success', props<{ users: UserGridObject }>()),
    getUsersFailure = createAction('getUsers failure', props<{ users: null }>());

export const
    createUser = createAction('createUser', props<{ input: CreateUserInput }>()),
    createUserSuccess = createAction('createUser success', props<{ user: UserObject }>()),
    createUserFailure = createAction('createUser failure', props<{ user: null }>());
