import { Injectable } from '@angular/core';
import {
    map,
    merge,
    of,
    shareReplay,
    Subject,
} from 'rxjs';
import { defaultAppConfig } from '../../content/service/types/user.types';

export interface AppConfig {
    language: string;
    inputStyle: string;
    colorScheme: string;
    floatLabelScheme: string;
    theme: string;
    ripple: boolean;
    menuType: string;
    scale: number;
    menuTheme: string;
    componentTheme: string;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    rightMenuVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
    searchBarActive: boolean;
    notificationsVisible: boolean;
    submenuVisible: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    config: AppConfig = defaultAppConfig;

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        rightMenuVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        searchBarActive: false,
        notificationsVisible: false,
        submenuVisible: false,
    };

    configUpdate$ = new Subject<AppConfig>();
    overlayOpen$ = new Subject<null>();

    loaderIconName$ = merge(of(this.config), this.configUpdate$).pipe(
        map((config) => {
            return config.colorScheme === 'light'
                ? 'loader'
                : 'loader-inv';
        }),
        shareReplay(1),
    );

    onMenuToggle(): void {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;

            if (this.state.overlayMenuActive) {
                this.overlayOpen$.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive =
                !this.state.staticMenuDesktopInactive;
        } else {
            this.state.staticMenuMobileActive =
                !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen$.next(null);
            }
        }
    }

    onOverlaySubmenuOpen(): void {
        this.overlayOpen$.next(null);
    }

    showRightMenu(): void {
        this.state.rightMenuVisible = true;
    }

    showConfigSidebar(): void {
        this.state.configSidebarVisible = true;
    }

    toggleSearchBar(): void {
        this.state.searchBarActive = !this.state.searchBarActive;
    }

    toggleNotifications(): void {
        this.state.notificationsVisible = !this.state.notificationsVisible;
    }

    isOverlay(): boolean {
        return this.config.menuType === 'overlay';
    }

    isDesktop(): boolean {
        return window.innerWidth > 991;
    }

    isStatic(): boolean {
        return this.config.menuType === 'static' && !this.state.submenuVisible;
    }

    isSlim(): boolean {
        return this.config.menuType === 'slim' && !this.state.submenuVisible;
    }

    isCompact(): boolean {
        return (
            this.config.menuType === 'compact' ||
            (!['overlay', 'horizontal'].includes(this.config.menuType) &&
                this.state.submenuVisible)
        );
    }

    isHorizontal(): boolean {
        return this.config.menuType === 'horizontal';
    }

    isMobile(): boolean {
        return !this.isDesktop();
    }

    onConfigUpdate(): void {
        this.configUpdate$.next(this.config);
    }
}
