import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-button-delete',
    templateUrl: 'button.component.html',
})
export class ButtonDeleteComponent extends ButtonComponent {

    @Input() override label = 'inputs.delete';
    @Input() override icon: string | null = 'pi-trash';
    @Input() override styleClass = 'p-button-danger';
    @Output() override clickEvent = new EventEmitter();

}
