<ng-container *ngIf="options.length > 0; else noOptions">
    <div class="input-grid input-grid-gaps">
        <app-input-select
            id="entity"
            [options]="options"
            [form]="form"
            [nameKey]="nameKey"
            [valueKey]="valueKey"
            [namePrefix]="selectLabelPrefix"
            class="fr-5"
        >
            <ng-template pTemplate="item" let-item="item">
                {{ item | formatPerson }}
            </ng-template>
            <ng-template pTemplate="selectedItem" let-item="item">
                {{ item | formatPerson }}
            </ng-template>
        </app-input-select>
    </div>

    <div class="buttons">
        <app-save-button [label]="selectButtonLabel" [onClick]="submit.bind(this)"></app-save-button>
        <app-cancel-button [label]="cancelButtonLabel" [onClick]="hide.bind(this)"></app-cancel-button>
    </div>

</ng-container>
<ng-template #noOptions>
    <p>{{ noOptionsLabel | translate }}</p>
</ng-template>
