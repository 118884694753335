import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputTextComponent } from './input-text.component';

@Component({
    selector: 'app-input-search',
    templateUrl: 'input-text.component.html',
})
export class InputSearchComponent extends InputTextComponent implements OnInit {
    @Input() override label = 'inputs.search';
    @Input() override id = 'search';
    @Input({ required: true }) override form!: FormGroup;
    @Input() override icon = 'pi-search';
    @Input() override placeholder: string | null = null;
    @Input() override autocomplete = true;
    @Input() override disabled = false;

    @ViewChild('input') input!: ElementRef;

    public override ngOnInit(): void {
        this.placeholder = this.label;
        this.label = '';
    }

    public focus(): void {
        this.input.nativeElement.focus();
    }
}
