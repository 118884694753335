import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output,
    QueryList, SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-input-select',
    templateUrl: 'input-select.component.html',
    styleUrl: 'input-select.component.scss',
})
export class InputSelectComponent implements OnInit, AfterContentInit, OnChanges {
    @Input() id = 'select';
    @Input({ required: true }) form!: FormGroup;
    @Input() options: Record<string, unknown>[] = [];
    @Input() nameKey: string | undefined = 'name';
    @Input() valueKey: string | undefined = 'name';
    @Input() groupNameKey = '';
    @Input() namePrefix = '';
    @Input() hideLabel = false;
    @Input() label = '';
    @Input() virtualScroll = false;
    @Input() disabled = false;
    @Input() shouldFilter = false;
    @Input() filterBy: string | null = null;
    @Input() autoDisplayFirst = true;
    @Input() group = false;
    @Input() translate = true;
    @Input() appendToBody = true;
    @Input() tooManyValues = false;
    @Input() showClear = false;
    @Output() filter = new EventEmitter<string>();

    @ContentChildren(PrimeTemplate) templates!: QueryList<any>;
    itemTemplate: TemplateRef<any> | null = null;
    selectedItemTemplate: TemplateRef<any> | null = null;
    groupTemplate: TemplateRef<any> | null = null;

    ngOnInit(): void {
        if (this.autoDisplayFirst && !this.form.getRawValue()[this.id]) {
            if (this.valueKey) {
                this.form.patchValue({
                    [this.id]: this.options?.[0]?.[this.valueKey] || null,
                });
            } else {
                this.form.patchValue({
                    [this.id]: this.options?.[0] || null,
                });
            }
        }
        if (!this.label) {
            if (this.namePrefix) {
                this.label = `${this.namePrefix}label`;
            } else {
                this.label = `inputs.${this.id}`;
            }
        }
        if (!this.groupNameKey) {
            this.groupNameKey = this.nameKey || '';
        }
    }

    ngAfterContentInit(): void {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;
                case 'selectedItem':
                    this.selectedItemTemplate = item.template;
                    break;
                case 'group':
                    this.groupTemplate = item.template;
                    break;
            }
        });
    }

    filterCallback(filter: string | null) {
        this.filter.emit(filter || '');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled']) {
            const currentValue = changes['disabled'].currentValue;
            if (currentValue) {
                this.form.controls[this.id].disable();
            } else {
                this.form.controls[this.id].enable();
            }
        }
    }
}
