import { UserDto } from '../../shared/models/user.dto';

export const authFeatureKey = 'auth';

export interface AuthState {
  loggedInUser: UserDto | null;
  token: string | null;
}

export const createInitialAuthState = (): AuthState => ({
  loggedInUser: null,
  token: window.localStorage.getItem('token') || null,
});
