// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const bankRedirectBase =
  'https://webapi.developers.erstegroup.com/api/csas/sandbox/v1/sandbox-idp/auth';
const bankCallbackUri = 'http://localhost:4200/api/v1/crm/handle-bank-login';
const bankClientId = '5a2189bc-00e6-4146-af06-b6fe92ced93f';
const bankResponseType = 'code';
const bankAccessType = 'offline';
const bankState = 'csas-auth';
const mapApiKey = 'CFHULRfIzEfSduJ7MhgrzC2NDzbGE1nG1fDaeo3ac-Y'; // TODO: replace Luke's own key

export const environment = {
  production: false,
  apiUrl: '/api/v1',
  // eslint-disable-next-line max-len
  bankRedirectUrl: `${bankRedirectBase}?redirect_uri=${bankCallbackUri}&client_id=${bankClientId}&response_type=${bankResponseType}&access_type=${bankAccessType}&state=${bankState}`,
  mapSourceUrl: `https://api.mapy.cz/v1/maptiles/basic/tiles.json?apikey=${mapApiKey}`,
  mapGeocodeUrl: `https://api.mapy.cz/v1/geocode?apikey=${mapApiKey}`,
  mapSuggestUrl: `https://api.mapy.cz/v1/suggest?apikey=${mapApiKey}`,
  mapRoutingUrl: `https://api.mapy.cz/v1/routing/route?apikey=${mapApiKey}`,
  mapReverseGeocodeUrl: `https://api.mapy.cz/v1/rgeocode?apikey=${mapApiKey}`,
  joditLicense: 'c7f0544b-706d-4a41-85b7-fd62bd50acc8',

  msClientId: 'cd7947aa-de31-4248-9d08-a160aa2a206b',
  msLogonUrl: 'http://localhost:4200/logon',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
