import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SubscriptionComponent } from '../../../helpers/subscription.component';

@Component({
    selector: 'app-input-text',
    templateUrl: 'input-text.component.html',
})
export class InputTextComponent extends SubscriptionComponent implements OnInit {
    @Input() label = '';
    @Input() hideLabel = false;
    @Input() placeholder: string | null = null;
    @Input({ required: true }) form!: FormGroup;
    @Input() id = 'text';
    @Input() icon: string | null = null;
    @Input() autocomplete = true;
    @Input() showArrows = true;
    @Input() disabled = false;
    @Input() readonly = false;
    @Input() min?: number = undefined;
    @Input() max?: number = undefined;
    @Input() inputCallback: Function|null = null;
    type = 'text';

    public ngOnInit(): void {
        if (this.label == '' && !this.placeholder) {
            this.label = `inputs.${this.id}`;
        }

        if (this.placeholder) {
            this.label = '';
        }
        if (this.inputCallback) {
            this.subs.push(
                this.form.controls[this.id].valueChanges.subscribe((it) => {
                    this.form.patchValue({
                        [this.id]: this.inputCallback!(it),
                    }, { emitEvent: false });
                }),
            );
        }
    }

    get markAsRequired(): boolean {
        const formField = this.form.get(this.id);
        return !!formField && formField.hasValidator(Validators.required) && !formField.value && formField.pristine;
    }
}
