import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatAddress',
    standalone: true,
})
export class FormatAddressPipe implements PipeTransform {
    transform(address?: any | null): string {
        return formatAddress(address);
    }
}

export function formatAddress(address?: any | null): string {
    if (!address) {
        return '';
    }

    return `${address.name}, ${address.location}`;
}
