import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pluralization',
    templateUrl: 'pluralization.component.html',
})
export class PluralizationComponent {
    @Input() count = 0;
    @Input() key!: string;

    get translationString() {
        return translationKey(this.count, this.key);
    }
}

export function pluralize(count: number, key: string, translate: TranslateService): string {
    return translate.instant(translationKey(count, key)).replaceAll('{N}', count.toString());
}

function translationKey(count: number, key: string): string {
    const prefix = `pluralization.${key}.`;

    if (count === 1) {
        return `${prefix}1`;
    } else if (count >= 2 && count <= 4) {
        return `${prefix}2-4`;
    } else {
        return `${prefix}5`;
    }
}
