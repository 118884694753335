import { UserGridObject } from '@it2go/types';

export const userFeatureKey = 'users';

// TODO smazat - potřeba doupravit user stránku na local store
export interface UserState {
    users: UserGridObject|null
}

export function createInitialUserState(): UserState {
    return {
        users: null,
    };
}
