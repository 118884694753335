import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from './auth.state';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectToken = createSelector(
  selectAuthState,
  (state) => state.token,
);
export const selectUser = createSelector(
  selectAuthState,
  (state) => state.loggedInUser,
);
