import { Subscription } from 'rxjs';
import { AfterContentInit, Component, ContentChildren, OnDestroy, QueryList } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';

@Component({
    template: '',
})
export abstract class SubscriptionComponent implements AfterContentInit, OnDestroy {

    @ContentChildren(PrimeTemplate) templates!: QueryList<any>;

    protected templateKeys: string[] = [];
    protected subs: (Subscription | undefined)[] = [];

    public ngOnDestroy(): void {
        this.subs.forEach((it) => it?.unsubscribe());
    }

    public ngAfterContentInit(): void {
        if (this.templateKeys.length) {
            this.templates.forEach((item) => {
                const key = item.getType();
                if (this.templateKeys.includes(key)) {
                    (this as any)[`${key}Template`] = item.template;
                }
            });
        }
    }

}
