import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList, TemplateRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { PrimeTemplate } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

@Component({
  selector: 'app-input-text-autocomplete',
  templateUrl: 'input-text-autocomplete.component.html',
  styleUrls: ['input-text-autocomplete.component.scss'],
})
export class InputTextAutocompleteComponent
  implements OnInit, AfterContentInit
{
  @Input() label: string = '';
  @Input() placeholder: string | null = null;
  @Input({ required: true }) form!: FormGroup;
  @Input() id: string = 'text';
  @Input() icon: string | null = null;
  @Input() suggestions: string[] = [];
  @Input() suggestFnc: (event: AutoCompleteCompleteEvent) => Promise<void> =
    async () => {};
  @Input() field: string | null = null;
  @Input() forceSelection: boolean = false;

  optionTemplate: TemplateRef<any> | undefined;
  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;

  public ngOnInit(): void {
    if (this.label == '' && !this.placeholder) {
      this.label = `inputs.${this.id}`;
    }

    if (this.placeholder) {
      this.label = '';
    }
  }

  ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.optionTemplate = item.template;
          break;
      }
    });
  }

  get markAsRequired(): boolean {
    const formField = this.form.get(this.id);
    return !!formField && formField.hasValidator(Validators.required) && !formField.value && formField.pristine;
  }
}
