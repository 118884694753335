import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { UpsertUserTableInput, UserTableGridObject, UserTableObject } from '@it2go/types';
import { GqlService } from '../../content/service/gql.service';
import { filterFrag } from '../../content/service/types/filter.types';
import { userTableFrag, userTableGridItemFrag } from './types/user-table.types';

/* @Deprecated() */
@Injectable()
export class UserService extends GqlService {

    getUserTables(input: GridInput | null) {
        return this.query<UserTableGridObject>(
            `
                query($input: GridInput) {
                    userTable {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userTableGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    upsertUserTable(input: UpsertUserTableInput) {
        return this.mutate<UserTableObject>(
            `
                mutation($input: UpsertUserTableInput!) {
                    userTable {
                        upsert(input: $input) {
                            ${userTableFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
