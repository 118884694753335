import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-check-or-cross',
  templateUrl: './check-or-cross.component.html',
  styleUrls: ['./check-or-cross.component.scss'],
})
export class CheckOrCrossComponent {
  @Input() value = true;
}
