<p-avatar *ngIf="initials && !src; else imageAvatar" [label]="initials" [size]="size" shape="circle">
</p-avatar>

<i *ngIf="onlineStatus && onlineStatus !== OnlineStatusEnum.Deactivated" [class]="'pi pi-circle-fill ' + size"
    [ngClass]="{
        'text-success': onlineStatus === OnlineStatusEnum.Online,
        'text-warning': onlineStatus === OnlineStatusEnum.Away,
        'text-secondary': onlineStatus === OnlineStatusEnum.Offline,
    }">
</i>

<ng-template #imageAvatar>
    <p-avatar *ngIf="src | async as src; else skeleton" [image]="src" [size]="size" shape="circle">
    </p-avatar>
</ng-template>

<ng-template #skeleton>
    <p-skeleton shape="circle" [size]="skeletonSize"></p-skeleton>
</ng-template>
