import { GridInput } from '@it2go/data-grid';
import { UserTableGridObject } from '@it2go/types';
import { createAction, props } from '@ngrx/store';
import { ColumnSetting } from "./shared.state";

export const
    setTableSetting = createAction('[Shared] setTableFilter', props<{ table: string, filter?: GridInput | null, columns?: string[]|null, columnSettings?: Record<string, ColumnSetting> }>()),
    setTableRefresher = createAction('[Shared] setTableRefresher', props<{ table: string, actions: string[] }>()),
    unsetTableRefresher = createAction('[Shared] unsetTableRefresher', props<{ table: string }>()),
    refreshTable = createAction('[Shared] refreshTable', props<{ table: string }>());

export const
    getUserTables = createAction('[Shared] getUserTables', props<{ input: GridInput | null }>()),
    getUserTablesSuccess = createAction('[Shared] getUserTables Success', props<{ userTables: UserTableGridObject }>()),
    getUserTablesFailure = createAction('[Shared] getUserTables Failure', props<{ userTables: null }>());
