import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SubscriptionComponent } from '../../../helpers/subscription.component';

@Component({
    selector: 'app-input-date',
    templateUrl: './input-date.component.html',
    styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent extends SubscriptionComponent implements OnInit {
    @Input() styleClass = '';
    @Input() id = 'date';
    @Input() label = '';
    @Input() hideLabel = false;
    @Input() showTime = false;
    @Input() disabled = false;
    @Input({ required: true }) form!: FormGroup;
    @Input() timeOnly = false;
    @Input() showButtonBar = true;

    protected readonly parseInt = parseInt;

    ngOnInit(): void {
        if (!this.label) {
            this.label = `inputs.${this.id}`;
        }
    }

    get markAsRequired(): boolean {
        const formField = this.form.get(this.id);
        return !!formField && formField.hasValidator(Validators.required) && !formField.value && formField.pristine;
    }

}
