<div class="flex align-items-center">
    <p-button icon="pi pi-chevron-left" styleClass="p-button-rounded p-button-secondary p-button-text"
              (onClick)="addDays(-1)"></p-button>
    <p-overlayPanel #op [dismissable]="false" [showCloseIcon]="true">
        <ng-template pTemplate="content">
            <div class="flex flex-column p-3">
                <div *ngIf="enablePeriodChange" class="flex flex-row mb-3">
                    <app-input-number
                        [form]="periodForm"
                        id="periodLength"
                        class="mr-3"
                        style="width: 9.8rem"
                    ></app-input-number>
                    <app-input-select-button
                        [form]="periodForm"
                        id="period"
                        [options]="periodOptions"
                        prefix="inputs.periodOptions."
                    ></app-input-select-button>
                </div>
                <p-calendar
                    [(ngModel)]="value"
                    [inline]="true"
                    [firstDayOfWeek]="1"
                    (ngModelChange)="select($event);op.toggle($event)"
                ></p-calendar>
            </div>
        </ng-template>
    </p-overlayPanel>
    <span class="m-3" (click)="op.toggle($event)" style="cursor: pointer">
        {{ form.value[id] | localDate : 'weekday' }}&nbsp;{{ form.value[id] | shortDate }}
        <span *ngIf="periodForm.value.period !== TimePeriodEnum.Day || (periodForm.value.periodLength ?? 1) > 1">
            - {{ form.value[endDateId] | localDate : 'weekday' }}&nbsp;{{ form.value[endDateId] | shortDate }}
        </span>
    </span>
    <p-button icon="pi pi-chevron-right" styleClass="p-button-rounded p-button-secondary p-button-text"
              (onClick)="addDays(1)"></p-button>
</div>
