import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sumItems',
    standalone: true,
})
export class SumItemsPipe implements PipeTransform {
    transform(items: any[], sumBy: string): number {
        if (!items || !items.length || !sumBy) {
            return 0;
        }
        return items.reduce((sum, item) => sum + item[sumBy], 0);
    }
}