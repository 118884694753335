<span [formGroup]="form" class="p-float-label p-input-icon-right w-full">
  <i class="pi pi-key"></i>
  <input
          [id]="id"
          [formControlName]="id"
          type="password"
          pInputText
          class="w-full"
          [ngClass]="{ 'p-disabled': disabled, 'border-primary border-1': markAsRequired }"
          [readOnly]="disabled"
          [autocomplete]="autocomplete ? 'on' : 'new-password'"
  />
  <label [for]="id">{{ label | translate }}</label>
</span>
<app-required-error [form]="form" [id]="id"></app-required-error>
