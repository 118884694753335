import { UserChip, UserChipRenderTypeEnum } from "../user-chip/user-chip.component.types";

export enum TableColumnType {
    String,
    Number,
    Date,
    Year,
    Age,
    Tag,
    UserChip
}

export type TableColumnFilterableType = 'int' | 'text' | 'select' | 'date';

export interface TableColumnFilterable {
    hideOperators?: boolean;
    type?: TableColumnFilterableType;
    selectOptions?: () => any[];
}

export interface TableColumnUserChipOpts {
    renderType?: UserChipRenderTypeEnum;
    dataCallback: (item: any) => UserChip;
}

export interface TableColumnTagOpts {
    labelPrefix: string;
    severity: (item: any) => string | null;
}

export interface TableColumn {
    name: string;
    type?: TableColumnType;
    dataKey?: string;
    sortable?: boolean;
    filterable?: boolean | TableColumnFilterable;

    // options for some of column types - if needed
    userChipOptions?: TableColumnUserChipOpts;
    tagOptions?: TableColumnTagOpts;
}
