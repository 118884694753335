import {
    AuthenticatedUserObject,
    OrganizationGridItemObject,
    OrganizationObject,
    ServiceGridItemObject,
    VisitHistoryObject,
} from '@it2go/types';
import { LanguageEnum } from '../../modules/shared/models/language.enum';

export const globalFeatureKey = 'global';

export interface GlobalState {
    isMobile: boolean;
    isSideBarShown: boolean;
    isSmallSideBarShown: boolean;
    language: LanguageEnum;

    organization: OrganizationObject | null;
    organizations: OrganizationGridItemObject[];
    activeIntegrations: Record<string, { data: string }>;
    organizationId: number;
    token: string;
    lastAction: { key: string; data: unknown };
    lastFailAction: string;
    services: ServiceGridItemObject[];

    rules: Record<string, Set<string>>;
    visitHistory: VisitHistoryObject[];
    currentUser: AuthenticatedUserObject | null;
}

export const createInitialGlobalState = (): GlobalState => ({
    isMobile: false,
    isSideBarShown: true,
    isSmallSideBarShown: false,
    language: LanguageEnum.CZECH,

    organization: null,
    organizations: [],
    activeIntegrations: {},
    organizationId: 0,
    lastAction: { key: '', data: '' },
    lastFailAction: '',
    services: [],

    rules: {},
    visitHistory: [],
    currentUser: null,
    token: '',
});
