<ng-container *ngIf="form" [formGroup]="form">
  <div class="group-header" [ngClass]="headerClass">
    <h6 class="group-label">
      {{ header }}
    </h6>
    <button
      (click)="addRow()"
      class="p-button-success p-button-text p-button-sm"
      icon="pi pi-plus"
      pButton
      pRipple
      type="button"
    ></button>
  </div>
  <ng-container [formArrayName]="formArrayName">
    <ng-container *ngFor="let row of formArray.controls; let idx = index">
      <div [formGroupName]="idx" [ngClass]="arrayClass">
        <!-- slot -->
        <ng-container
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{ index: idx, formGroup: row }"
        >
        </ng-container>

        <button
          (click)="deleteRow(idx)"
          class="p-button-danger p-button-text p-button-sm"
          icon="pi pi-trash"
          pButton
          pRipple
          type="button"
        ></button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
