import { Injectable } from '@angular/core';
import { GqlService } from '../../content/service/gql.service';
import { CategoryGridObject, DownloadFileExternalInput, DownloadFileInput, DownloadStatsFileInput, FileExternalDownloadObject, FileGridObject, FileObject, FilePresignedDownloadObject, FilePresignedUploadObject, OkObject, UploadFileConfirmInput, UploadFileInput } from '@it2go/types';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from '../../content/service/types/filter.types';
import { categoriesGridItemFrag, filesGridItemFrag } from '../../../services/types/files.types';
import { DeleteFileInput } from '@libs/types/src/file-manager/input-type/delete-file.input';
import { DeleteLinkInput, LinkFileInput } from '@libs/types/src/file-manager/input-type/link-file.input';
import { FileLinkObject } from '@libs/types/src/file-manager/object-type/file-link.type';

@Injectable()
export class FileService extends GqlService {

    constructor(
        apollo: Apollo,
        private readonly http: HttpClient,
    ) {
        super(apollo);
    }

    getPresignedUrl(input: UploadFileInput): Observable<FilePresignedUploadObject> {
        return this.mutate<FilePresignedUploadObject>(
            `
                mutation($input: UploadFileInput!) {
                    file {
                        presignedUpload(input: $input) {
                            id
                            url
                        }
                    }
                }
            `,
            { input },
        );
    }

    confirmFileUpload(input: UploadFileConfirmInput) {
        return this.mutate<FileObject>(
            `
                mutation($input: UploadFileConfirmInput!) {
                    file {
                        confirm(input: $input) {
                            name
                            path
                            owner
                        }
                    }
                }
            `,
            { input },
        );
    }

    uploadFile(input: { url: string, file: File }) {
        return this.http.put(input.url, input.file);
    }

    getCategories(input: GridInput | null) {        
        return this.query<CategoryGridObject>(
            `
                query($input: GridInput) {
                    fileCategory {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${categoriesGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getFiles(input: GridInput | null) {
        return this.query<FileGridObject>(
            `
                query($input: GridInput) {
                    file {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${filesGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    downloadFile(input: DownloadFileInput) {
        return this.query<FilePresignedDownloadObject>(
            `
                query($input: DownloadFileInput!) {
                    file {
                        presigneDownload(input: $input) {
                            url
                        }
                    }
                }
            `,
            { input },
        );
    }

    downloadWithStats(input: DownloadStatsFileInput) {
        return this.query<FilePresignedDownloadObject>(
            `
                query($input: DownloadStatsFileInput!) {
                    file {
                        downloadWithStats(input: $input) {
                            url
                        }
                    }
                }
            `,
            { input },
        );
    }

    downloadExternal(input: DownloadFileExternalInput) {
        return this.query<FileExternalDownloadObject>(
            `
                query($input: DownloadFileExternalInput!) {
                    file {
                        downloadExternal(input: $input) {
                            url
                            name
                        }
                    }
                }
            `,
            { input },
        );
    }

    getLinks() {
        return this.query<[FileLinkObject]>(
            `
                query {
                    file {
                        getLinks {
                            id
                            code
                            dateFrom
                            dateTo
                            used
                            user
                        }
                    }
                }
            `,
        );
    }

    deleteFile(input: DeleteFileInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DeleteFileInput!) {
                    file {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteLink(input: DeleteLinkInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DeleteLinkInput!) {
                    file {
                        deleteLink(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    externalLink(input: LinkFileInput) {
        return this.mutate<{ url: string }>(
            `
                mutation($input: LinkFileInput!) {
                    file {
                        externalLink(input: $input) {
                            code
                        }
                    }
                }
            `,
            { input },
        );
    }

}
