import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumber',
    standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
    transform(value: number, decimals = -1, isMonetary = false): string {
        return formatNumber(value, decimals, isMonetary);
    }
}

/**
 * Formatuje cislo. Umi zaokrouhlit na zadany pocet mist, naformatovat jako penezni castku - pridat tisicove oddelovace a v pripade celeho cisla ,- na konec.
 * @param value - cislo k formatovani
 * @param decimals - pocet desetinnych mist, -1 znamena nechat jak je
 * @param isMonetary - true pokud se ma formatovat jako penezni castka (tisicove oddelovace, ,- na konec)
 */
export function formatNumber(value: number, decimals = -1, isMonetary = false): string {
    if (!value && value !== 0) {
        return '';
    }

    let rounded = value;

    if (decimals > -1) {
        const decimalPrecision = Math.pow(10, decimals);
        rounded = (Math.round(rounded * decimalPrecision)) / decimalPrecision;
    }

    const number = String(rounded);
    const parts = number.split('.');

    let integerPart = parts[0];
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    let decimalPart = '';
    if (parts[1]) {
        decimalPart = (',' + parts[1]).padEnd(decimals + 1, '0');
    } else if (decimals > 0) {
        decimalPart = ',' + '0'.repeat(decimals);
    }

    if (isMonetary) {
        decimalPart = ',-';
    }

    return integerPart + decimalPart;
}
