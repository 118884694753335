import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-confirmation',
    templateUrl: 'confirmation.component.html',
    styleUrls: ['confirmation.component.scss'],
})
export class ConfirmationComponent {
    @Input() label!: string;
    @Input() note = '';
    @Output() confirm: EventEmitter<void> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    emitConfirm() {
        this.confirm.emit();
    }

    emitCancel() {
        this.cancel.emit();
    }
}
