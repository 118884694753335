import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-input-wysiwyg',
    templateUrl: 'input-wysiwyg.component.html',
})
export class InputWysiwygComponent {
    @Input() placeholder = '';
    @Input() selectedData = '';
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;
    @Input() height = 500;
    @Output() selectedDataChange = '';

    public language: string | null = this.translate.currentLang;

    public config = {
        placeholder: this.placeholder.length
            ? this.translate.instant(this.placeholder)
            : '',
    };

    constructor(private readonly translate: TranslateService) {}
}
