import { Component, Input, OnInit } from '@angular/core';
import { OnlineStatusEnum } from '../../../../../../../../types/src/common/enum/online-status.enum';
import { isObservable, Observable, of } from 'rxjs';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    @Input() src: Observable<string> | string | null = null;
    @Input() initials?: string = undefined;
    @Input() onlineStatus: OnlineStatusEnum | null = null;
    @Input() size?: 'large' | 'xlarge' = undefined;

    skeletonSize?: string;

    ngOnInit(): void {
        if (!isObservable(this.src)) {
            this.src = this.src !== null ? of(this.src as string) : null;
        }

        this.skeletonSize = this.size === 'xlarge' ? '5rem' : this.size === 'large' ? '3rem' : '2rem';
    }

    protected readonly OnlineStatusEnum = OnlineStatusEnum;
}
