import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UserChip, UserChipRenderTypeEnum } from './user-chip.component.types';

@Component({
    selector: 'app-user-chip',
    templateUrl: './user-chip.component.html',
    styleUrls: ['./user-chip.component.scss'],
})
export class UserChipComponent implements OnInit {
    @Input({ required: true }) user!: UserChip;
    @Input() renderType?: UserChipRenderTypeEnum;

    @ViewChild('detailsOverlay') detailsOverlay: OverlayPanel | undefined;

    type?: UserChipRenderTypeEnum;

    initials!: string;
    fullName!: string;

    ngOnInit(): void {
        this.type = this.renderType ? this.renderType : this.user.renderType ? this.user.renderType : UserChipRenderTypeEnum.User;
        this.initials = `${this.user.name.slice(0,1)}${this.user.surname.slice(0,1)}`;
        this.fullName = `${(this.user.titleBeforeName ? this.user.titleBeforeName + ' ' : '')} ${this.user.name} ${this.user.surname} ${(this.user.titleAfterName ? ' ' + this.user.titleAfterName : '')}`;
    }

    showDetails(event: MouseEvent) {
        if (this.detailsOverlay) {
            this.detailsOverlay.show(event);
        }
    }

    hideDetails() {
        if (this.detailsOverlay) {
            this.detailsOverlay.hide();
        }
    }

    protected readonly UserChipRenderTypeEnum = UserChipRenderTypeEnum;
}
