import { Component, Input, TemplateRef } from '@angular/core';
import { KeyValue } from '@angular/common';
import { SubscriptionComponent } from '../../../helpers/subscription.component';

@Component({
    selector: 'app-list-card',
    templateUrl: './list-card.component.html',
})
export class ListCardComponent extends SubscriptionComponent {

    @Input() title!: string;
    @Input() items!: KeyValue<string, any>[];

    protected override templateKeys = ['value'];
    protected valueTemplate?: TemplateRef<any>;

}
