import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-input-existing',
    templateUrl: './input-existing.component.html',
    styleUrls: ['./input-existing.component.scss'],
})
export class InputExistingComponent {
    @Input() options: Record<string, string | number>[] = [];
    @Input() id = 'existing';
    @Input() nameKey = 'name';
    @Input() valueKey = 'id';
    @Input({ required: true }) form!: FormGroup;
    @Input() buttonLabel = 'inputs.selectUser';
    @Input() dialogHeader = 'inputs.selectUserForm.title';
    @Input() selectLabelPrefix = 'inputs.selectUserForm.';
    @Input() selectButtonLabel = 'inputs.select';
    @Input() cancelButtonLabel = 'inputs.cancel';

    selectExistingDialogVisible = false;

    get formValue(): string | number | null {
        return this.form.value[this.id];
    }

    get selectedExistingName(): string {
        if (!this.formValue) return '';
        const selectedOption = this.options.find((option) => option[this.valueKey] === this.formValue);
        if (selectedOption) {
            return selectedOption[this.nameKey] as string;
        }

        const titleBeforeName = this.form.value.titleBeforeName ?? '';
        const name = this.form.value.name ?? '';
        const surname = this.form.value.surname ?? '';
        const titleAfterName = this.form.value.titleAfterName ?? '';
        return `${titleBeforeName} ${name} ${surname} ${titleAfterName}`.trim();
    }

    showExistingSelect(): void {
        this.selectExistingDialogVisible = true;
    }

    onExistingSelected(entity: string | number | null): void {
        this.selectExistingDialogVisible = false;
        this.form.patchValue({
            [this.id]: entity ?? null,
        });
    }

    onExistingSelectCancel(): void {
        this.selectExistingDialogVisible = false;
    }

}