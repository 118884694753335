import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-input-check',
    templateUrl: 'input-check.component.html',
    styleUrls: ['input-check.component.scss'],
})
export class InputCheckComponent implements OnInit, OnChanges {

    @Input({ required: true }) form!: FormGroup;
    @Input() id = 'check';
    @Input() label = '';
    @Input() showLabel = true;
    @Input() disabled = false;
    @Input() labelBoldPrefix = '';
    @Input() readonly = false;

    ngOnInit(): void {
        if (this.showLabel && !this.label) {
            this.label = `inputs.${this.id}`;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled']) {
            const currentValue = changes['disabled'].currentValue;
            if (currentValue) {
                this.form.controls[this.id].disable();
            } else {
                this.form.controls[this.id].enable();
            }
        }
    }

}
