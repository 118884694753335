import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateInputId',
  standalone: true,
})
export class GenerateInputIdPipe implements PipeTransform {
  transform(value: string, prefix: string) {
    return generateInputId(value, prefix);
  }
}

export function generateInputId(id: string, prefix: string) {
  if (!id) {
    return '';
  }
  if (!prefix) {
    return id;
  }
  return prefix + id[0].toUpperCase() + id.slice(1);
}
