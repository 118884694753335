import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-date-occupied',
    templateUrl: './date-occupied.component.html',
})
export class DateOccupiedComponent {
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;

}