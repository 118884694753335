import { Component, Input } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-audit-log-tree',
    templateUrl: 'audit-log-tree.component.html',
    styleUrl: 'audit-log-tree.component.scss',
})
export class AuditLogTreeComponent {

    @Input() tree!: TreeNode[];
    @Input() entityName: string = '';

    public expand(expand: boolean = true) {
        this.expandTree(this.tree, expand);
    }

    private expandTree(nodes: TreeNode[], expand: boolean): void {
        nodes.forEach((it) => {
            it.expanded = expand;
            if (it.children?.length) {
                this.expandTree(it.children, expand);
            }
        });
    }

    protected readonly Array = Array;
}
