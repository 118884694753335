import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-input-password',
    templateUrl: 'input-password.component.html',
})
export class InputPasswordComponent {
    @Input() label = 'password';
    @Input() id = 'password';
    @Input() disabled = false;
    @Input({ required: true }) form!: FormGroup;
    @Input() autocomplete = true;

    get markAsRequired(): boolean {
        const formField = this.form.get(this.id);
        return (
            !!formField &&
            formField.hasValidator(Validators.required) &&
            !formField.value &&
            formField.pristine
        );
    }
}
