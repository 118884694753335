import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FilterInput } from '@it2go/data-grid';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import config from '../../../../../config/config';
import { SubscriptionComponent } from '../../../helpers/subscription.component';
import { TimePeriodEnum } from '../../../../content/enum/services/timePeriod.enum';

export const FILTER_OPS_INT = [
    'EQ',
    'LT',
    'LTE',
    'GT',
    'GTE',
    'BETWEEN',
    'NBETWEEN',
    'NEQ',
];

export const FILTER_OPS_DATE = [
    'EQ',
    'LT',
    'LTE',
    'GT',
    'GTE',
    'BETWEEN',
    'NBETWEEN',
];

@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent extends SubscriptionComponent implements OnInit, OnChanges {
    @Input() column!: string;
    @Input() type: any = 'text';
    @Input() typed: string = 'text';
    @Input() initial: FilterInput | null = null;

    value: string | null = null;
    value2: string | null = null;
    date: string | null = null;
    date2: string | null = null;
    selectValue: string = '';

    @Output() valueChange = new EventEmitter<FilterInput>();
    debouncer: Subject<string> = new Subject<string>();

    selectedOp = 'LIKE';
    @Input() filterOps: string[] = [
        'LIKE',
        'STARTS',
        'ENDS',
        'EQ',
        'NEQ',
    ];

    constructor() {
        super();
        this.subs.push(
            this.debouncer.pipe(
                debounceTime(config.debounceTime),
            ).subscribe(() => {
                if (this.type == 'int') {
                    this.value = Number(this.value) ? Number(this.value).toString() : '';
                    this.value2 = Number(this.value2) ? Number(this.value2).toString() : '';
                }
                this.valueChange.emit({
                    value: [this.value || '', this.value2 || ''],
                    column: this.column,
                    operator: this.selectedOp,
                });
            }),
        );
    }

    valueChanged() {
        this.debouncer.next('');
        return true;
    }

    clearValue() {
        this.value = null;
        this.value2 = null;
        this.date = null;
        this.date2 = null;
    }

    ngOnInit(): void {
        if (this.type == 'int') {
            this.filterOps = FILTER_OPS_INT;
        } else if (this.type == 'date') {
            this.filterOps = FILTER_OPS_DATE;
        } else if (Array.isArray(this.type)) {
            this.selectedOp = 'EQ';
            this.typed = 'select';
            this.selectValue = this.type[0]?.value || '';
            return;
        }

        this.typed = String(this.type);
        this.selectedOp = this.filterOps[0];
    }

    ngOnChanges(changes: any): void {
        if (changes.initial) {
            const values = changes.initial.currentValue;
            if (this.type === 'date') {
                let d: string | null = values?.value?.[0] || null;
                if (d) {
                    const dd = new Date(d);
                    this.date = `${dd.getDate()}.${dd.getMonth() + 1}.${dd.getFullYear()}`;
                }
                d = values?.value?.[1] || null;
                if (d) {
                    const dd = new Date(d);
                    this.date2 = `${dd.getDate()}.${dd.getMonth() + 1}.${dd.getFullYear()}`;
                }
            }

            this.value = values?.value?.[0] || null;
            this.value2 = values?.value?.[1] || null;
            this.selectedOp = values?.operator || this.filterOps[0];
        }

    }

    updateDate(key: number, date: Date|null) {
        if (date) {
            const d = new Date(date);
            d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
            if (key === 1) {
                this.value = d.toISOString();
            } else {
                this.value2 = d.toISOString();
            }
        } else {
            if (key === 1) {
                this.value = null;
            } else {
                this.value2 = null;
            }
        }
        this.valueChanged();
    }

    protected readonly TimePeriodEnum = TimePeriodEnum;
}
