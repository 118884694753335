import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Map } from 'ol';
import { Address, MapService } from '../../map/map.service';
import { MapComponent } from '../../map/map.component';
import { AddressDetailObject, RegionalStructureObject } from '@it2go/types';

@Component({
    selector: 'app-input-address',
    templateUrl: './input-address.component.html',
    styleUrls: ['./input-address.component.scss'],
})
export class InputAddressComponent implements OnInit {
    @Input() styleClass = '';
    @Input() id = 'address';
    @Input() label = '';
    @Input() disabled = false;
    @Input({ required: true }) form!: FormGroup;
    @Input() translatePrefix = 'inputs.address';
    @Input() showTitle = false;
    @Output() addressChanged = new EventEmitter();

    suggestions: AddressDetailObject[] = [];
    mapObject: Map = this.mapService.createMapObject();
    displayMap = false;
    mapClickAddress: AddressDetailObject | null = null;

    @ViewChild('map') map!: MapComponent;

    constructor(private mapService: MapService) {}

    public ngOnInit(): void {
        if (!this.label) {
            this.label = `inputs.${this.id}`;
        }

        this.mapService.registerMapClickAddressHandler(
            this.mapObject,
            (address: Address, coords) => {
                this.mapClickAddress = this.mapAddress(address as AddressDetailObject);
                this.mapService.createAddressPopup(
                    this.mapObject,
                    address,
                    coords,
                );
                this.mapService.createAddressMarker(
                    this.mapObject,
                    address,
                    true,
                );
            },
        );
    }

    private mapAddress(address: AddressDetailObject) {
        return {
            zip: address?.zip ?? '',
            name: address?.name ?? '',
            regionalStructure: address?.regionalStructure.map((item) => {
                return {
                    name: item.name,
                    type: item.type,
                    isoCode: item.isoCode,
                };
            }) as RegionalStructureObject[],
            position: {
                lat: address?.position.lat ?? 0,
                lon: address?.position.lon ?? 0,
            },
            location: address?.location ?? '',
        };
    }

    protected confirmAddress(): void {
        this.form.controls[this.id]?.setValue(this.mapClickAddress);
        this.displayMap = false;
        this.addressChanged.emit();
    }

    async suggestAddresses(event: AutoCompleteCompleteEvent) {
        const suggestions = await this.mapService.suggestAddresses(event.query);
        this.suggestions = suggestions.map((item) => this.mapAddress(item as AddressDetailObject));
    }

    protected async showMap(show = true): Promise<void> {
        this.map.open();
        await this.showOnMap(show);
    }

    protected async showOnMap(show = true): Promise<void> {
        this.displayMap = show;
        if (show) {
            await this.mapService.showAddressOnMap(
                this.mapObject,
                this.form.value[this.id],
            );
        }
    }

    suggestAddressesBound = this.suggestAddresses.bind(this);
}
