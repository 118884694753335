<div class="flex align-items-center" [formGroup]="form">
    <p-inputSwitch
        class="flex"
        [formControlName]="id"
        [disabled]="disabled"
    ></p-inputSwitch>
    <div *ngIf="label"
        class="{{ 'cursor-pointer ' + textStyleClass }} ml-2"
        (click)="switch()"
    >{{ label | translate }}</div>
</div>
