export const filesGridItemFrag = `
    id
    path
    url
    categoryName
    categoryId
    note
    date
    kind
    uploadedBy
    name
    size
    accessCount
    folderId
    stats {
        date
        type
        userName
        ip
    }
`;

export const categoriesGridItemFrag = `
    id
    name
    parent
    filesCount
    code
`;
