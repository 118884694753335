import {
    Component,
    ContentChild,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-array-wrapper',
    templateUrl: './form-array-wrap.component.html',
    styleUrls: ['./form-array-wrap.component.scss'],
})
export class FormArrayWrapComponent implements OnInit {
    @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;
    @Input() header = '';

    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) formArrayName!: string;
    @Input({ required: true }) elementConstructor!: () =>
        | FormGroup
        | FormControl;

    @Input() headerClass:
        | string
        | string[]
        | Set<string>
        | { [klass: string]: any } = 'flex align-items-center';
    @Input() arrayClass:
        | string
        | string[]
        | Set<string>
        | { [klass: string]: any } = 'flex gap-2 align-items-center';

    formArray!: FormArray;

    ngOnInit(): void {
        this.formArray = this.form.get(this.formArrayName) as FormArray;
    }

    addRow(): void {
        this.formArray.push(this.elementConstructor());
    }

    deleteRow(index: number): void {
        this.formArray.removeAt(index);
    }
}
