<p-button
    class="w-full"
    label="{{ header || '' | translate }}"
    (onClick)="show()"
></p-button>
<p-dialog
    header="{{ header || '' | translate }}"
    [(visible)]="visible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{maxWidth: '1300px', minWidth: '400px'}"
    [contentStyle]="{ overflow: 'visible'}"
>
    <ng-content></ng-content>
</p-dialog>
