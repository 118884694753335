// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createSelector, MemoizedSelector } from '@ngrx/store';

type Selectors<T> = {
    [Key in keyof T]: MemoizedSelector<object, T[Key], any>;
};

export class SelectorFactory<T extends object> {
    private fakeState: Selectors<T> = {} as Selectors<T>;

    constructor(
        private readonly selector: MemoizedSelector<object, T>,
        private readonly initState: T,
    ) {
        Object.keys(initState).forEach((key) => {
            this.fakeState[key] = createSelector(
                selector,
                (state: T) => state[key],
            ) as any;
        });
    }

    create() {
        return this.fakeState;
    }
}
