import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { stripPrefix } from './common';

export interface reducerOptions {
    dataKey?: string|null
}

export class ReducerFactory {

    constructor(
        private readonly actions: Record<string, ActionCreator>,
    ) {
    }

    create(...names: string[]) {
        return names.map((name) => this.createReducer(name));
    }

    createOpt(name: string, options: reducerOptions = {}) {
        return this.createReducer(name, options);
    }

    private createReducer(name: string, options: reducerOptions = {}): ReducerTypes<any, any> {
        const dataKey = options.dataKey || stripPrefix(name);

        return on(
            this.actions[`${name}Success`],
            this.actions[`${name}Failure`],
            (state, newState: Record<string, unknown>) => ({
                ...state,
                [dataKey]: newState[dataKey],
            }),
        );
    }

}
