import { Component, Input } from '@angular/core';
import { InputRadioComponent } from './input-radio.component';

@Component({
    selector: 'app-input-radio-yes-no',
    templateUrl: './input-radio.component.html',
    styleUrls: ['input-radio.component.scss'],
})
export class InputRadioYesNoComponent extends InputRadioComponent {

    @Input() override options: any[] | null = [
        { name: 'inputs.yes', value: true },
        { name: 'inputs.no', value: false },
    ];

}
