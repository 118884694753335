import { Pipe, PipeTransform } from '@angular/core';
import { formatPerson } from './format-person.pipe';
import { PersonContactGridItemObject } from '@it2go/types';

@Pipe({
    name: 'formatPersonContact',
    standalone: true,
})
export class FormatPersonContactPipe implements PipeTransform {
    transform(personContact?: PersonContactGridItemObject | null): string {
        return formatPersonContact(personContact);
    }
}

export function formatPersonContact(personContact?: PersonContactGridItemObject | null): string {
    if (!personContact) {
        return '';
    }

    return `${formatPerson(personContact.user)}, ${personContact.relation}${
        personContact.user.phone ? `, ${personContact.user.phone}` : ''
    }`.trim();
}
