import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatPerson',
    standalone: true,
})
export class FormatPersonPipe implements PipeTransform {
    transform(person?: any | null): string {
        return formatPerson(person);
    }
}

export function formatPerson(person?: any | null): string {
    if (!person) {
        return '';
    }

    return `${person.titleBeforeName || ''} ${person.name || ''} ${person.surname || ''} ${person.titleAfterName || ''}`.trim() || person.email;
}
