import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TableColumn, TableColumnType } from '../../table-v2.component.types';

@Component({
    selector: 'app-table-cell-content',
    templateUrl: './table-cell-content.component.html',
    styleUrls: ['./table-cell-content.component.scss'],
})
export class TableCellContentComponent implements OnInit {
    @Input() template: TemplateRef<any> | null = null;
    @Input() column!: TableColumn;
    @Input() item: any;
    @Input() rowIndex!: number;

    value: unknown;
    type!: TableColumnType;

    constructor() {}

    ngOnInit(): void {
        this.value = this.getValue(this.item, this.column);
        this.type = this.column.type || TableColumnType.String;
    }

    private getValue(item: any, col: TableColumn): unknown {
        let value = item;
        const colKey = col.dataKey || col.name;

        if (!colKey) {
            return null;
        }

        colKey.split('.').forEach((key) => {
            value = value[key];
        });

        return value;
    }

    protected readonly TableColumnType = TableColumnType;
}
