<p-button icon="pi pi-arrows-v" [pTooltip]="'history.expand' | translate" [rounded]="true" [text]="true"
          severity="secondary" (onClick)="expand()"></p-button>
<p-button icon="pi pi-bars" [pTooltip]="'history.collapse' | translate" [rounded]="true" [text]="true"
          severity="secondary" (onClick)="expand(false)"></p-button>
<p-tree class="" [value]="tree" [emptyMessage]="'history.noChange' | translate">
    <ng-template let-node pTemplate="default">
        <div class="flex flex-row">
            <b>{{ entityName + '.fields.' + node.label | translate }}</b>
            <b *ngIf="node.data" class="mr-2">:</b>
            <div *ngIf="!node.isArray" class="flex flex-column">
                    <span
                        [ngClass]="{ 'text-success': !node.oldData, 'text-primary': node.oldData }">{{ node.data }}</span>
                <span *ngIf="!node.data">&nbsp;</span>
                <span *ngIf="node.oldData" [ngClass]="{ 'text-danger': !node.data }">({{ node.oldData }})</span>
            </div>
            <div *ngIf="node.isArray" class="flex flex-column">
                <br/>
                <div *ngFor="let row of node.data; let rowIndex = index">
                    <ng-container *ngIf="Array.isArray(row)">
                        <div class="flex">
                            <ng-container *ngFor="let r of row; let index = index">
                                <ng-container *ngIf="r._key">
                                    <span *ngIf="index > 0" class="mr-2">,</span>
                                    <b class="mr-2">{{ r._key | translate }}:</b>
                                    <span class="flex flex-column">
                                                    <span
                                                        [ngClass]="{ 'text-success': !node.oldData?.[rowIndex]?.[index]?._value, 'text-primary': node.oldData?.[rowIndex]?.[index]?._value }">{{ r._value || '-' }}</span>
                                                    <ng-container
                                                        *ngIf="node.oldData?.[rowIndex]?.[index]?._value as oldValue">
                                                        <span *ngIf="r._value != oldValue">
                                                            ({{ oldValue }})
                                                        </span>
                                                    </ng-container>
                                                </span>
                                </ng-container>
                                <ng-container *ngIf="!r._key">{{ r }}</ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div *ngIf="!Array.isArray(row)" class="flex flex-column">
                            <span
                                [ngClass]="{ 'text-success': !node.oldData?.[rowIndex], 'text-primary': node.oldData?.[rowIndex] }">{{ row }}</span>
                        <span>({{ node.oldData?.[rowIndex] }})</span>
                    </div>
                </div>
                <!-- Old data overflowing data.length -->
                <ng-container *ngFor="let row of node.oldData; let rowIndex = index">
                    <ng-container *ngIf="rowIndex >= node.data.length">
                        <ng-container *ngIf="Array.isArray(row)">
                            <div class="flex">
                                <ng-container *ngFor="let r of row; let index = index">
                                    <ng-container *ngIf="r._key">
                                        <span *ngIf="index > 0" class="mr-2">,</span>
                                        <b class="mr-2">{{ r._key | translate }}:</b>
                                        <span class="text-danger">
                                                            {{ node.oldData?.[rowIndex]?.[index]?._value }}
                                                        </span>
                                    </ng-container>
                                    <span *ngIf="!r._key" class="text-danger">{{ r }}</span>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div *ngIf="!Array.isArray(row)" class="flex flex-column">
                            <span class="text-danger">{{ node.oldData?.[rowIndex] }}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-template>
</p-tree>
