<p-messages [severity]="severity" *ngIf="visible" [@visibilityAnimation]>
    <ng-template pTemplate>
        <span *ngIf="severity === 'success'" class="p-message-icon pi pi-check"></span>
        <span *ngIf="severity === 'warn'" class="p-message-icon pi pi-exclamation-triangle"></span>
        <span *ngIf="severity === 'info'" class="p-message-icon pi pi-info-circle"></span>
        <span *ngIf="severity === 'error'" class="p-message-icon pi pi-times-circle"></span>

        <span class="p-message-summary mr-2">{{ 'messages.severity.' + severity | translate }}</span>

        <ng-container *ngIf="message">{{message | translate}}</ng-container>
        <ng-content></ng-content>

        <span *ngIf="closeable" class="p-message-close p-link cross">
            <span class="p-message-summary pi pi-times" (click)="close()"></span>
        </span>
    </ng-template>
</p-messages>
