import {
    AuthenticatedUserObject,
    OrganizationGridItemObject,
    OrganizationObject,
    RuleObject,
    ServiceGridItemObject,
    VisitHistoryGridItemObject,
    VisitHistoryObject,
} from '@it2go/types';
import { createAction, props } from '@ngrx/store';
import { LanguageEnum } from '../../modules/shared/models/language.enum';

export const setMobile = createAction(
    '[Global] Set Mobile',
    (isMobile: boolean) => ({ isMobile }),
);

export const setShowSidebar = createAction(
    '[Global] Set Show Sidebar',
    (isSideBarShown: boolean) => ({ isSideBarShown }),
);

export const setShowSmallSidebar = createAction(
    '[Global] Set Show Small Sidebar',
    (isSmallSideBarShown: boolean) => ({ isSmallSideBarShown }),
);

export const setLanguage = createAction(
    '[Global] Change Language',
    (language: LanguageEnum) => ({ language }),
);

export const globalSuccess = createAction(
    '[Global] globalSuccess',
    props<{ input: { key: string; data: unknown } }>(),
);

export const globalApiFailure = createAction(
    '[Global] globalApiFailure',
    props<{ key: string; }>(),
);

export const setOrganization = createAction(
        '[Global] setOrganization',
        props<{ organization: OrganizationObject | null }>(),
    ),
    setOrganizations = createAction(
        '[Global] setOrganizations',
        props<{ organizations: OrganizationGridItemObject[] }>(),
    ),
    setCurrentOrganizationId = createAction(
        '[Global] setCurrentOrganizationId',
        props<{ organizationId: number; token?: string }>(),
    ),
    setCurrentActiveIntegrations = createAction(
        '[Global] setActiveIntegrations',
        props<{ activeIntegrations: Record<string, { data: string }> }>(),
    );

export const setServices = createAction(
    '[Global] setServices',
    props<{ services: ServiceGridItemObject[] }>(),
);

export const getRules = createAction('[Global] getRules'),
    getRulesSuccess = createAction(
        '[Global] getRulesSuccess',
        props<{ rules: RuleObject[] }>(),
    ),
    getRulesFailure = createAction(
        '[Global] getRulesFailure',
        props<{ rules: RuleObject[] }>(),
    );

export const updateVisitHistoryData = createAction(
        '[Global] updateVisitHistoryData',
    ),
    updateVisitHistoryDataSuccess = createAction(
        '[Global] updateVisitHistoryData Success',
        props<{ visitHistory: VisitHistoryObject }>(),
    ),
    updateVisitHistoryDataFailure = createAction(
        '[Global] updateVisitHistoryData Failure',
        props<{ visitHistory: null }>(),
    );

export const watchVisitHistory = createAction('[Global] watchVisitHistory'),
    watchVisitHistoryReceived = createAction(
        '[Global] watchVisitHistoryReceived',
        props<{ visitHistory: VisitHistoryObject }>(),
    );

export const getVisitHistory = createAction('[Global] getVisitHistory'),
    getVisitHistorySuccess = createAction(
        '[Global] getVisitHistory Success',
        props<{ visitHistory: VisitHistoryGridItemObject[] }>(),
    ),
    getVisitHistoryFailure = createAction(
        '[Global] getVisitHistory Failure',
        props<{ visitHistory: null }>(),
    );

export const whoami = createAction('[Global] whoami'),
    whoamiSuccess = createAction(
        '[Global] whoami Success',
        props<{ whoami: AuthenticatedUserObject }>(),
    ),
    whoamiFailure = createAction(
        '[Global] whoami Failure',
        props<{ whoami: null }>(),
    );

export const watchEvents = createAction('[Global] watchEvents'),
    ignore = createAction('[Global] ignore');
