import { createReducer } from '@ngrx/store';
import { createInitialUserState } from './user.state';
import * as actions from './user.actions';
import { ReducerFactory } from '../../../shared/store/common/reducer.factory';

const factory = new ReducerFactory(actions)

export const userReducer = createReducer(
    createInitialUserState(),
    ...factory.create(
        'getUsers',
    ),
);
