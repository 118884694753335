<span [formGroup]="form" class="p-float-label w-full">
  <textarea
          [rows]="rows"
          [formControlName]="id"
          [id]="id"
          pInputTextarea
          class="w-full"
          [ngClass]="{ 'border-primary border-1': markAsRequired }"
  >
  </textarea>
  <label [for]="id">{{ label | translate }}</label>
</span>
<app-required-error [form]="form" [id]="id"></app-required-error>
