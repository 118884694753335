import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-input-multi-select',
    templateUrl: 'input-multi-select.component.html',
    styleUrls: ['input-multi-select.component.scss'],
})
export class InputMultiSelectComponent implements OnInit, AfterContentInit {
    @Input() id = 'select';
    @Input({ required: true }) form!: FormGroup;
    @Input() options: Record<string, unknown>[] = [];
    @Input() nameKey = 'name';
    @Input() valueKey = 'id';
    @Input() label = '';
    @Input() namePrefix = '';
    @Input() shouldFilter = false;
    @Input() disabled = false;
    @Input() filterBy: string | null = null;
    @Input() display = 'comma';
    @Input() appendToBody = true;
    @Input() tooManyValues = false;
    @Input() showClear = false;
    @Output() filter = new EventEmitter<string>();

    @ContentChildren(PrimeTemplate) templates!: QueryList<any>;
    itemTemplate: TemplateRef<any> | null = null;
    selectedItemsTemplate: TemplateRef<any> | null = null;

    ngOnInit(): void {
        if (!this.label) {
            if (this.namePrefix) {
                this.label = `${this.namePrefix}label`;
            } else {
                this.label = `inputs.${this.id}`;
            }
        }
    }

    ngAfterContentInit(): void {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;
                case 'selectedItems':
                    this.selectedItemsTemplate = item.template;
                    break;
            }
        });
    }

    filterCallback(filter: string | null) {
        this.filter.emit(filter || '');
    }
}
