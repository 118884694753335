import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubscriptionComponent } from '../../../helpers/subscription.component';

@Component({
    selector: 'app-input-phone',
    templateUrl: 'input-phone.component.html',
    styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent extends SubscriptionComponent implements OnInit {
    @Input() label = 'inputs.phone';
    @Input() id = 'phone';
    @Input({ required: true }) form!: FormGroup;
    @Input() icon = 'pi-phone';
    @Input() placeholder: string | null = null;
    @Input() disabled = false;
    runs = 0;

    constructor(
        private readonly fb: FormBuilder,
    ) {
        super();
    }

    phoneForm = this.fb.group({
        country: this.fb.control<string | null>(null, Validators.required),
    });

    // TODO doplnit predvyplneni podle zeme, kterou ma sluzba nastavenou
    phoneOptions = [
        {
            name: 'CZ',
            value: '+420 999 999 999',
        },
        {
            name: 'SK',
            value: '+421 999 999 999',
        },
        {
            name: 'UA',
            value: '+380 99 999 99 99',
        },
        {
            name: 'DE',
            value: '+49 9999 99?9 9999',
        },
        {
            name: 'AT',
            value: '+43 999 999 9999',
        },
        {
            name: 'PL',
            value: '+48 999 999 999',
        },
        {
            name: 'GB',
            value: '+44 99 9999 9999',
        },
        {
            name: 'HU',
            value: '+36 999 999 999',
        },
    ];

    public ngOnInit(): void {
        if (this.label == '' && !this.placeholder) {
            this.label = `inputs.${this.id}`;
        }

        if (this.placeholder) {
            this.label = '';
        }

        this.subs.push(
            this.form.controls[this.id].valueChanges.subscribe(async (phone: string | null) => {
                if (!phone?.startsWith('+420') && this.runs === 1) {
                    this.runs++;

                    this.form.patchValue({
                        [this.id]: this.form.value[this.id],
                    });
                }

                if (phone && this.runs === 0) {
                    this.runs++;

                    if (phone?.startsWith('+420')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[0].value,
                        });
                    } else if (phone?.startsWith('+421')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[1].value,
                        });
                    } else if (phone?.startsWith('+380')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[2].value,
                        });
                    } else if (phone?.startsWith('+49')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[3].value,
                        });
                    } else if (phone?.startsWith('+43')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[4].value,
                        });
                    } else if (phone?.startsWith('+48')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[5].value,
                        });
                    } else if (phone?.startsWith('+44')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[6].value,
                        });
                    } else if (phone?.startsWith('+36')) {
                        this.phoneForm.patchValue({
                            country: this.phoneOptions[7].value,
                        });
                    }
                }
            }),
        );
    }

    get markAsRequired(): boolean {
        const formField = this.form.get(this.id);
        return !!formField && formField.hasValidator(Validators.required) && !formField.value && formField.pristine;
    }
}
