import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: 'button.component.html',
})
export class ButtonComponent {
    @Input() label = '';
    @Input() icon: string | null = null;
    @Input() styleClass = 'p-button-primary';
    @Input() disabled = false;
    @Input() onClick: CallableFunction = function () {};
    @Output() clickEvent = new EventEmitter();
}
