import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-input-radio',
    templateUrl: './input-radio.component.html',
    styleUrls: ['input-radio.component.scss'],
})
export class InputRadioComponent implements OnChanges {
    @Input() horizontal = true;
    @Input() options: any[]|null = [];
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;
    @Input() valueKey = 'value';
    @Input() nameKey = 'name';
    @Input() autoDisplayFirst = false;

    ngOnChanges(): void {
        if (this.autoDisplayFirst && !this.form.value[this.id]) {
            if (this.valueKey) {
                this.form.patchValue({
                    [this.id]: this.options?.[0]?.[this.valueKey] || null,
                });
            } else {
                this.form.patchValue({
                    [this.id]: this.options?.[0] || null,
                });
            }
        }
    }
}
