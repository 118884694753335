<div class="flex flex-row align-items-center">
  <p-inputSwitch
    class="flex"
    [(ngModel)]="value"
    (onChange)="emitChangeEvent()"
  ></p-inputSwitch>
  <div
    class="{{ 'cursor-pointer ' + textStyleClass }}"
    (click)="switch()"
  >{{ label }}</div>
</div>
