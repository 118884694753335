<p-dialog header="{{ translatePrefix + dialogTitle + 'Title' | translate }}" [(visible)]="dialogVisible"
          [modal]="true" [draggable]="false" [resizable]="false" [style]="{maxWidth: '1300px'}">
    <div class="mb-5"></div>
    <ng-container *ngTemplateOutlet="dialog; context: { hide: closeDialog, data: dialogData }"></ng-container>
</p-dialog>

<div class="w-full flex" [classList]="containerClasses">
    <div *ngIf="!hideTitle || dialog || !hideCreate" class="flex flex-row justify-content-between mt-2 mb-4">
        <div *ngIf="!hideTitle" class="text-xl">{{ (title ? title : (translatePrefixV2 ? translatePrefixV2 + 'labelMulti' : translatePrefix + 'title')) | translate }}</div>
        <ng-container *ngTemplateOutlet="dialogButton"></ng-container>
        <button
                *ngIf="!dialogButton && dialog"
                pButton
                pRipple
                icon="pi pi-plus"
                class="p-button-success add-button"
                (click)="showDialog(true, null)"
        ></button>
        <button
                *ngIf="!hideCreate"
                pButton
                pRipple
                icon="pi pi-plus"
                class="p-button-success add-button"
                (click)="createCallback.emit()"
        ></button>
    </div>

    <ng-container *ngTemplateOutlet="subtitle"></ng-container>

    <div [formGroup]="form" class="flex flex-row my-2 mr-2 search-bar">
        <div class="flex flex-row">
            <button *ngIf="filterableColumns.length" type="button" pButton pRipple icon="pi pi-filter-slash" (click)="clearFilters()" class="mr-2 p-button-outlined" [pTooltip]="'table.clearFiltersExt' | translate" tooltipPosition="bottom" [label]="'table.clearFilters' | translate"></button>
            <button *ngIf="showExport" type="button" pButton pRipple icon="pi pi-file" (click)="exportCSV()" class="mr-2 p-button-secondary" pTooltip="export CSV" tooltipPosition="bottom"></button>
            <button *ngIf="showExport" type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-secondary mr-2" pTooltip="export XLS" tooltipPosition="bottom"></button>
            <button *ngIf="showExport" type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-secondary mr-2" pTooltip="export PDF" tooltipPosition="bottom"></button>
            <p-multiSelect
                *ngIf="showColumnVisiblity"
                [options]="columnsVisibilityOptions"
                [filter]="false"
                formControlName="columns"
                optionLabel="name"
            >
                <ng-template pTemplate="selectedItems">{{ 'table.visibleColumns' | translate }}</ng-template>
                <ng-template pTemplate="item" let-item>{{ (translatePrefixV2 ? translatePrefixV2 + 'fields.' + item.name : translatePrefix + item.name) | translate }}</ng-template>
            </p-multiSelect>
        </div>
        <div *ngIf="showSearch" class="flex justify-content-end">
            <app-input-search [form]="form"></app-input-search>
        </div>
    </div>

    <p-contextMenu #cm [model]="contextMenu"></p-contextMenu>
    <p-table
            [value]="items || []"
            [rows]="gridData?.['paging']?.['itemsPerPage'] || 10"
            (sortFunction)="sortChange($event)"
            [customSort]="true"
            [rowHover]="true"
            [loading]="isLoading | async"
            [dataKey]="dataKey"
            styleClass="p-datatable-gridlines"
            selectionMode="multiple"
            [(selection)]="selectedRows"
            (selectionChange)="selectionChange($event)"
            [reorderableColumns]="true"
            (onColReorder)="tableReorder($event)"
            [resizableColumns]="true"
            (onColResize)="columnResize($event)"
            [contextMenu]="cm"
            [(contextMenuSelection)]="selectedContextRow"
            [frozenValue]="frozenRows"
            [scrollable]="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="!hideCheckboxes" style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of frozenColumnNames()" pFrozenColumn>
                    {{ (translatePrefixV2 ? translatePrefixV2 + 'fields.' + col : translatePrefix + col) | translate }}
                </th>
                <th *ngIf="rowExpansion" style="width: 4rem"></th>
                <th *ngIf="!hideDetail || dialog">{{ detailHeader? (translatePrefix + detailHeader | translate) : '' }}</th>
                <th *ngIf="itemTemplates['link']"></th>

                <ng-container *ngFor="let col of visibleColumns">
                    <th
                        *ngIf="itemTemplates[col + 'Header']"
                        [attr.colname]="col"
                        pReorderableColumn
                        pResizableColumn
                        [style]="{ width: getColumnSize(col) }"
                    >
                        <ng-container *ngTemplateOutlet="itemTemplates[col + 'Header']"></ng-container>
                    </th>
                    <th
                            *ngIf="sortableColumns.includes(col) && !itemTemplates[col + 'Header']"
                            [attr.colname]="col"
                            [pSortableColumn]="col"
                            pReorderableColumn
                            pResizableColumn
                            [style]="{ width: getColumnSize(col) }"
                    >
                        <p-sortIcon *ngIf="sortableColumns.includes(col)" [field]="col"></p-sortIcon>
                        {{ (translatePrefixV2 ? translatePrefixV2 + 'fields.' + col : translatePrefix + col) | translate }}
                    </th>
                    <th
                            *ngIf="!sortableColumns.includes(col) && !itemTemplates[col + 'Header']"
                            [attr.colname]="col"
                            pReorderableColumn
                            pResizableColumn
                            [style]="{ width: getColumnSize(col) }"
                    >
                        {{ (translatePrefixV2 ? translatePrefixV2 + 'fields.' + col : translatePrefix + col) | translate }}
                    </th>
                </ng-container>
                <th *ngIf="!hideDelete">{{ deleteHeader? (translatePrefix + deleteHeader | translate) : '' }}</th>
            </tr>
            <tr *ngIf="filterableColumns.length">
                <th *ngIf="!hideCheckboxes" style="width: 4rem"></th>
                <th *ngFor="let col of frozenColumnNames()" pFrozenColumn></th>
                <th *ngIf="rowExpansion" style="width: 4rem"></th>
                <th *ngIf="!hideDetail || dialog"></th>
                <th *ngIf="itemTemplates['link']"></th>

                <ng-container *ngFor="let col of visibleColumns">
                    <th>
                        <app-table-filter
                            [id]="'__filter_' + col"
                            *ngIf="filterableColumns.includes(col)"
                            [column]="col"
                            [type]="filterableColumnTypes[col] || 'text'"
                            [initial]="getFilter(col)"
                            (valueChange)="filterChange($event)"
                        >
                        </app-table-filter>
                    </th>
                </ng-container>
                <th *ngIf="!hideDelete"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-expanded="expanded" let-rowIndex="rowIndex">
            <tr
                    [pSelectableRow]="item"
                    [pSelectableRowIndex]="rowIndex"
            >
                <td *ngIf="!hideCheckboxes"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <p-tableCheckbox [pSelectableRow]="item" [value]="item"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of frozenColumnNames()" pFrozenColumn class="frozenIndex"
                    [attr.data-ui-el]="col"
                    [pContextMenuRow]="{ column: col, item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenColumnContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <ng-container *ngIf="!itemTemplates[col]">
                        <ng-container
                                *ngIf="isDate(item[col], col); else notADate">{{ item[col] | date: 'd.M.y' }}</ng-container>
                        <ng-template #notADate>{{ getValue(item, col) }}</ng-template>
                    </ng-container>

                    <ng-container *ngIf="itemTemplates[col]">
                        <ng-container
                                *ngTemplateOutlet="itemTemplates[col]; context: { data: getValue(item, col), item: item, rowIndex: rowIndex }">
                        </ng-container>
                    </ng-container>
                </td>

                <td *ngIf="rowExpansion"
                    [pContextMenuRow]="{ column: 'expansion', item, rowIndex }"
                    (contextmenu)="changeContextMenu(bodyContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                    (click)="rowExpanded.emit({expanded, item})"
                >
                    <button type="button" pButton pRipple [pRowToggler]="item"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngIf="!hideDetail || dialog" class="tableIconCell"
                    [pContextMenuRow]="{ column: 'detail', item, rowIndex }"
                    (contextmenu)="changeContextMenu(bodyContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <p-button
                            icon="pi pi-folder-open"
                            styleClass="p-button-info p-button-text tableIcon"
                            pTooltip="{{ translatePrefix + 'editButtonTitle' | translateOr }}"
                            (click)="showDialog(true, item)"
                    ></p-button>
                </td>

                <td *ngIf="itemTemplates['link']"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <ng-container
                        *ngTemplateOutlet="itemTemplates.link; context: { item: item }">
                    </ng-container>
                </td>

                <!-- Column-->
                <td
                        *ngFor="let col of visibleColumns"
                        [attr.data-ui-el]="col"
                        [ngStyle]="{ 'max-width': getColumnSize(col) }"
                        [pContextMenuRow]="{ column: col, item, rowIndex }"
                        (contextmenu)="changeContextMenu(bodyContextMenu)"
                        [ngClass]="{'stripped': isStripped(item, col, rowIndex), 'btNone': matchPreceding(item, col, rowIndex) && false }"
                >
<!--                    <ng-container *ngIf="!itemTemplates[col] && !matchPreceding(item, col, rowIndex)">-->
                    <ng-container *ngIf="!itemTemplates[col]">
                        <ng-container
                                *ngIf="isDate(item[col], col); else notADate">{{ item[col] | date: 'd.M.y' }}</ng-container>
                        <ng-template #notADate>{{ getValue(item, col) }}</ng-template>
                    </ng-container>

<!--                    <ng-container *ngIf="itemTemplates[col] && !matchPreceding(item, col, rowIndex)">-->
                    <ng-container *ngIf="itemTemplates[col]" >
                        <ng-container
                                *ngTemplateOutlet="itemTemplates[col]; context: { data: getValue(item, col), item: item, rowIndex: rowIndex }">
                        </ng-container>
                    </ng-container>
                </td>

                <td *ngIf="!hideDelete" class="tableIconCell" [pContextMenuRow]="{ column: 'delete', item, rowIndex }"
                    (contextmenu)="changeContextMenu(bodyContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <p-button
                            icon="pi pi-trash"
                            styleClass="p-button-danger p-button-text inline-button tableIcon"
                            pTooltip="{{ translatePrefix + 'deleteButtonTitle' | translateOr }}"
                            (click)="deleteCallback.emit(item); refreshData()"
                            *ngIf="showDelete"
                    ></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="frozenbody" let-item let-expanded="expanded" let-rowIndex="rowIndex">
            <tr
                    [pSelectableRow]="item"
                    [pSelectableRowIndex]="rowIndex"
            >
                <td>
                    <p-tableCheckbox [pSelectableRow]="item" [value]="item"></p-tableCheckbox>
                </td>

                <td *ngFor="let col of frozenColumnNames()" pFrozenColumn class="frozenIndex2"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                    [attr.data-ui-el]="col"
                >
                    <ng-container *ngIf="!itemTemplates[col]">
                        <ng-container
                                *ngIf="isDate(item[col], col); else notADate">{{ item[col] | date: 'd.M.y' }}</ng-container>
                        <ng-template #notADate>{{ getValue(item, col) }}</ng-template>
                    </ng-container>

                    <ng-container *ngIf="itemTemplates[col]">
                        <ng-container
                                *ngTemplateOutlet="itemTemplates[col]; context: { data: getValue(item, col), item: item, rowIndex: rowIndex }">
                        </ng-container>
                    </ng-container>
                </td>

                <td *ngIf="rowExpansion" [pContextMenuRow]="{ column: 'expansion', item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <button type="button" pButton pRipple [pRowToggler]="item"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngIf="!hideDetail || dialog" class="tableIconCell"
                    [pContextMenuRow]="{ column: 'detail', item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <p-button
                            icon="pi pi-folder-open"
                            styleClass="p-button-info p-button-text tableIcon"
                            pTooltip="{{ translatePrefix + 'editButtonTitle' | translateOr }}"
                            (click)="showDialog(true, item)"
                    ></p-button>
                </td>

                <td *ngIf="itemTemplates.link">
                    <ng-container
                        *ngTemplateOutlet="itemTemplates.link; context: { item: item }">
                    </ng-container>
                </td>

                <!-- Column-->
                <td
                        *ngFor="let col of visibleColumns"
                        [ngStyle]="{ 'max-width': getColumnSize(col) }"
                        [pContextMenuRow]="{ column: col, item, rowIndex }"
                        (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                        [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                        [attr.data-ui-el]="col"
                >
                    <ng-container *ngIf="!itemTemplates[col]">
                        <ng-container
                                *ngIf="isDate(item[col], col); else notADate">{{ item[col] | date: 'd.M.y' }}</ng-container>
                        <ng-template #notADate>{{ getValue(item, col) }}</ng-template>
                    </ng-container>

                    <ng-container *ngIf="itemTemplates[col]">
                        <ng-container
                                *ngTemplateOutlet="itemTemplates[col]; context: { data: getValue(item, col), item: item, rowIndex: rowIndex }">
                        </ng-container>
                    </ng-container>
                </td>

                <td *ngIf="!hideDelete" class="tableIconCell" [pContextMenuRow]="{ column: 'delete', item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                >
                    <p-button
                            icon="pi pi-trash"
                            styleClass="p-button-danger p-button-text inline-button tableIcon"
                            pTooltip="{{ translatePrefix + 'deleteButtonTitle' | translateOr }}"
                            (click)="deleteCallback.emit(item)"
                            *ngIf="showDelete"
                    ></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer" *ngIf="footerTemplate">
            <ng-container *ngTemplateOutlet="footerTemplate; context: {items, visibleColumns}"></ng-container>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-item>
            <tr class="w-full">
                <td colspan="999">
                    <ng-container *ngTemplateOutlet="rowExpansion; context: {item}"></ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="loadingicon">
            <img *ngIf="(darkLogo$ | async) === false" src="../../../../../../assets/images/XC_logo-horizont.svg"/>
            <img *ngIf="darkLogo$ | async" src="../../../../../../assets/images/XC_logo-horizont-inv.svg"/>
        </ng-template>
    </p-table>
    <p-paginator
            *ngIf="!!gridData"
            [first]="((gridData?.['paging']?.['page'] || 1) - 1) * (gridData?.['paging']?.['itemsPerPage'] || 10)"
            [rows]="gridData?.['paging']?.['itemsPerPage'] || 10"
            [totalRecords]="gridData?.['paging']?.['total'] || 0"
            [rowsPerPageOptions]="rowsPerPageOptions"
            (onPageChange)="pageChange($event)"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="tableSummary(gridData)"
    ></p-paginator>
</div>
