import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../../../services/session.service';

@Pipe({
  name: 'shortDate',
  standalone: true,
})
export class ShortDatePipe implements PipeTransform {
  constructor(private session: SessionService) {}

  public transform(value: any, format = 'd.M.y', ...args: any[]): any {
    return shortDate(value, this.session.locale, format);
  }

}

export function shortDate(date: string, locale: string, format = 'd.M.y'): string {
  if (!date) {
    return '';
  }

  return formatDate(date, format, locale)
}
