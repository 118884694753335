// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, ActionCreator } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { stripPrefix } from './common';

export interface effectCreateOptions {
    dataKey?: string | null;
    actions?: ActionCreator[];
}

type SimpleEffects<T> = {
    [Key in keyof T]: Observable<Action>;
};

type ExtEffects<T> = {
    [Key in keyof T as `${Key}Ext`]: (
        opt: effectCreateOptions,
    ) => Observable<Action>;
};

type TableRefresherEffects<T> = {
    [Key in keyof T as `${Key}Table`]: (
        ...actions: ActionCreator[]
    ) => Observable<Action>;
};

type Effects<T> = SimpleEffects<T> & ExtEffects<T> & TableRefresherEffects<T>;

export class EffectFactory<T> {
    private fakeEffect: Effects<T> = {} as Effects<T>;

    constructor(
        private readonly actions$: Actions,
        private readonly actions: T,
        private readonly api: any,
    ) {}

    create() {
        Object.keys(this.actions).forEach((it) => {
            const name = it.replace(/\$$/, '');
            this.fakeEffect[it] = this.effect(name);
            this.fakeEffect[`${it}Ext`] = (opt) => this.effect(name, opt);
            this.fakeEffect[`${it}Table`] = (...actions) =>
                this.tableRefresher(name, ...actions);
        });

        return this.fakeEffect;
    }

    effect(
        name: string,
        options: effectCreateOptions = { dataKey: null, actions: [] },
    ): Observable<Action> {
        return createEffect(() =>
            this.actions$.pipe(
                ofType(this.actions[name], ...(options?.actions || [])),
                switchMap((input: any) =>
                    this.api[name]('input' in input ? input.input : input).pipe(
                        map((result) =>
                            this.actions[`${name}Success`]({
                                [options.dataKey || stripPrefix(name)]: result,
                            }),
                        ),
                        catchError(() =>
                            of(
                                this.actions[`${name}Failure`]({
                                    [options.dataKey || stripPrefix(name)]:
                                        null,
                                }),
                            ),
                        ),
                    ),
                ),
            ),
        ) as unknown as Observable<Action>;
    }
}
