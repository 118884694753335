import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-cancel-button',
    templateUrl: 'button.component.html',
})
export class CancelButtonComponent extends ButtonComponent {

    @Input() override label = 'inputs.cancel';
    @Input() override icon: string | null = 'pi-times';
    @Input() override styleClass = 'p-button-danger';
    // Deprecated - use clickEvent
    @Input() override onClick: CallableFunction = function () {};
    @Output() override clickEvent = new EventEmitter();

}
