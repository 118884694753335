import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { SelectorFactory } from './common/selector.factory';
import { createInitialSharedState, sharedFeatureKey, SharedState, TableSetting } from './shared.state';

export const selectSharedState = createFeatureSelector<SharedState>(sharedFeatureKey);

const factory = new SelectorFactory(selectSharedState, createInitialSharedState()).create();

export const selectTableSettings: (table: string) => MemoizedSelector<any, TableSetting> =
    (table: string) => createSelector(selectSharedState, (state: SharedState) => state?.tableSettings?.[table] || { filter: null, columns: [] });
export const selectTableRefreshers = factory.tableRefresher;
export const selectTableSettingsFetched = factory.tableSettingsFetched;
