import { AfterContentInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-inner-modal',
    templateUrl: 'inner-modal.component.html',
    styleUrls: ['inner-modal.component.scss'],
})
export class InnerModalComponent implements AfterContentInit {

    @Input() header?: string;
    @ContentChildren(PrimeTemplate) templates!: QueryList<any>;

    buttonTemplate?: TemplateRef<any>;
    visible = false;

    ngAfterContentInit(): void {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'button':
                    this.buttonTemplate = item.template;
                    break;
            }
        });
    }

    show(show: boolean = true) {
        this.visible = show;
    }

}
