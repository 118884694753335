import { Operations } from '@it2go/types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SelectorFactory } from '../../modules/shared/store/common/selector.factory';
import { createInitialGlobalState, GlobalState } from './global.state';
import { OrganizationSettingTypeEnum } from '@libs/types/src/organization/enum/organization-setting-type.enum';

export const selectGlobalState = createFeatureSelector<GlobalState>('global');
const factory = new SelectorFactory(
    selectGlobalState,
    createInitialGlobalState(),
).create();

export const selectIsMobile = createSelector(
    selectGlobalState,
    (state: GlobalState) => state.isMobile,
);
export const selectSidebarShown = createSelector(
    selectGlobalState,
    (state: GlobalState) => state.isSideBarShown,
);
export const selectSmallSidebarShown = createSelector(
    selectGlobalState,
    (state: GlobalState) => state.isSmallSideBarShown,
);

export const selectLastAction = factory.lastAction;
export const selectLastFailAction = factory.lastFailAction;

export const selectOrganizations = factory.organizations;
export const selectCurrentOrganizationId = factory.organizationId;
export const selectToken = factory.token;
export const selectCurrentOrganization = factory.organization;
export const selectActiveIntegrations = factory.activeIntegrations;
export const isCurrentIntegrationActive = (
    integration: OrganizationSettingTypeEnum,
) =>
    createSelector(selectGlobalState, (state) => {
        return !!state.activeIntegrations[integration];
    });
export const selectCurrentActiveIntegration = (
    integration: OrganizationSettingTypeEnum,
) =>
    createSelector(selectGlobalState, (state) => {
        return state.activeIntegrations[integration];
    });
export const selectCurrentDonationForms = createSelector(
    selectGlobalState,
    (state: GlobalState) => {
        const data = JSON.parse(
            state.activeIntegrations[OrganizationSettingTypeEnum.DONATION_FORM]
                ?.data || '{}',
        );
        return data.forms || [];
    },
);
export const selectCurrentDonationFormsFilter = createSelector(
    selectGlobalState,
    (state: GlobalState) => {
        const data = JSON.parse(
            state.activeIntegrations[OrganizationSettingTypeEnum.DONATION_FORM]
                ?.data || '{}',
        );
        return (data.forms || []).map((it: any) => ({
            name: it.name,
            value: it.id,
        }));
    },
);

export const selectServices = factory.services;

export const isAllowed = (operation: Operations) =>
    createSelector(selectGlobalState, (state) => {
        const [resource, action] = operation.split('.');

        return state.rules[resource]?.has(action) || false;
    });

export const selectCurrentUserId = createSelector(
    selectGlobalState,
    (state: GlobalState) => state.currentUser?.id || '',
);
export const selectVisitHistory = factory.visitHistory;
export const selectCurrentUser = factory.currentUser;
